import React, { Component } from "react";
import { withNavBars } from "../../HOCs";
import { AlertConfig } from "./alertConfig";

class AlertConfigParent extends Component {
  render() {
    return (
      <div>
        <AlertConfig />
      </div>
    );
  }
}

export default withNavBars(AlertConfigParent);
