import React from "react";
import { Box, Typography } from "@mui/material";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useStyles } from "./style";

const data = [
  {
    name: "Lorem Ipsum",
    value: 17,
  },
  {
    name: "Lorem Ipsum",
    value: 20,
  },
  {
    name: "Lorem Ipsum",
    value: 17,
  },
  {
    name: "Lorem Ipsum",
    value: 20,
  },
  {
    name: "Lorem Ipsum",
    value: 6,
  },
  {
    name: "Lorem Ipsum",
    value: 30,
  },
  {
    name: "Lorem Ipsum",
    value: 18,
  },
];

// tool tip
const Tip = ({ setShowTooltip, fill, ...rest }) => {
  const classes = useStyles();
  const [payload, setPayload] = React.useState(rest?.payload);

  React.useEffect(() => {
    rest?.payload?.length && setPayload(rest?.payload);
  }, [rest?.payload]);

  return payload?.length ? (
    <div
      onMouseLeave={() => setShowTooltip(false)}
      onMouseMove={(e) => e.stopPropagation()}
    >
      <Box style={{ backgroundColor: fill ?? "#FF9340" }} />
      <Typography className={classes.tooltip}>
        {payload?.[0]?.payload?.value}%
      </Typography>
    </div>
  ) : null;
};

const formatter = (value) => `${value}%`;

export const GraphComp = () => {
  const [showTooltip, setShowTooltip] = React.useState(false);

  const classes = useStyles();

  return (
    <>
      <ResponsiveContainer className={classes.graphDiv}>
        <LineChart
          className={classes.chart}
          onMouseLeave={() => setShowTooltip(false)}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="8" stroke="#DFE2E6" />
          <XAxis dataKey="name" onMouseEnter={() => setShowTooltip(true)} />
          <YAxis
            tickFormatter={formatter}
            onMouseEnter={() => setShowTooltip(true)}
          />
          {showTooltip && (
            <Tooltip
              // Animation is a bit weird when the tooltip shows up after hidding
              isAnimationActive={false}
              // Send props down to get the payload
              content={<Tip setShowTooltip={setShowTooltip} fill={"#FF9340"} />}
              // We need this to manage visibility ourselves
              wrapperStyle={{ visibility: "visible", pointerEvents: "auto" }}
            />
          )}

          <Line
            animationDuration={false}
            type="monotone"
            dataKey="value"
            stroke="#464775"
            strokeWidth={2}
            onMouseEnter={() => setShowTooltip(true)}
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};
