import * as React from "react"

const ReportsSvg = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Group 93692"
        width={26}
        height={26}
        {...props}
    >
        <path
            data-name="icons8-combo-chart (1)"
            d="M19.039 3.914a2.247 2.247 0 0 0-1.687.72 2.294 2.294 0 0 0-.57 1.532 2.412 2.412 0 0 0 .136.8l-2.936 2.308a2.184 2.184 0 0 0-2.778.194L8.601 7.981a2.469 2.469 0 0 0 .081-.467 2.294 2.294 0 0 0-.565-1.532 2.247 2.247 0 0 0-1.687-.72 2.247 2.247 0 0 0-1.687.72 2.361 2.361 0 0 0 0 3.065 2.286 2.286 0 0 0 3.212.117l2.608 1.489a2.468 2.468 0 0 0-.079.464 2.294 2.294 0 0 0 .565 1.532 2.338 2.338 0 0 0 3.374 0 2.294 2.294 0 0 0 .565-1.532 2.412 2.412 0 0 0-.136-.8l2.93-2.303a2.248 2.248 0 0 0 2.941-.311 2.361 2.361 0 0 0 0-3.065 2.247 2.247 0 0 0-1.684-.724Zm0 1.351a.811.811 0 0 1 .677.266 1.014 1.014 0 0 1 0 1.27.811.811 0 0 1-.677.266.786.786 0 0 1-.636-.249.676.676 0 0 0-.076-.114.96.96 0 0 1-.189-.537.972.972 0 0 1 .223-.635.811.811 0 0 1 .678-.267ZM6.428 6.614a.811.811 0 0 1 .677.266 1.014 1.014 0 0 1 0 1.27.811.811 0 0 1-.677.266.811.811 0 0 1-.677-.266 1.014 1.014 0 0 1 0-1.27.811.811 0 0 1 .677-.266Zm6.305 3.6a.786.786 0 0 1 .636.249.676.676 0 0 0 .076.114.96.96 0 0 1 .189.537.972.972 0 0 1-.223.635.811.811 0 0 1-.677.266.811.811 0 0 1-.677-.266 1.014 1.014 0 0 1 0-1.27.811.811 0 0 1 .677-.26Zm6.3 0a2.264 2.264 0 0 0-2.251 2.26v6.753a2.254 2.254 0 1 0 4.508 0v-6.753a2.265 2.265 0 0 0-2.254-2.255Zm0 1.351a.893.893 0 0 1 .9.9v6.753a.903.903 0 1 1-1.806 0v-6.744a.893.893 0 0 1 .909-.904Zm-12.61 0a2.264 2.264 0 0 0-2.251 2.261v5.4a2.254 2.254 0 1 0 4.508 0v-5.4a2.264 2.264 0 0 0-2.254-2.253Zm0 1.351a.893.893 0 0 1 .9.9v5.4a.903.903 0 1 1-1.806 0v-5.4a.892.892 0 0 1 .909-.892Zm6.308 2.249a2.265 2.265 0 0 0-2.249 2.263v1.8a2.255 2.255 0 1 0 4.509 0v-1.8a2.265 2.265 0 0 0-2.257-2.255Zm0 1.351a.893.893 0 0 1 .9.9v1.8a.903.903 0 1 1-1.807 0v-1.8a.893.893 0 0 1 .91-.892Z"
            fill="#848484"
        />
    </svg>
)

export default ReportsSvg
