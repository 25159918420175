import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Subheader } from "../../components/subHeader/SubHeader";
import { TableWithPagination } from "../../components/tableWithPagination";
import { config } from "../../config";
import { NetworkCall } from "../../networkCall";
import { NetWorkCallMethods } from "../../utils";
import { TotalReportsStyles } from "./style";

export const TotalReports = () => {
  const classes = TotalReportsStyles();

  // Table - 1
  const Tablepath_1 = [
    "projectName",
    "noOfActionsDone",
    "status",
    "actualStartDate",
    "actualEndDate",
    "assignedTo",
  ];

  const Tableheading_1 = [
    { title: "Project Name", field: "projectName" },
    { title: "No. Of Actions Done", field: "noOfActionsDone" },
    { title: "Status", field: "status" },
    { title: "Actual Start Date", field: "actualStartDate" },
    { title: "Actual End Date", field: "actualEndDate" },
    { title: "Assigned To", field: "assignedTo" },
  ];

  function createData_1(
    projectName,
    noOfActionsDone,
    status,
    actualStartDate,
    actualEndDate,
    assignedTo
  ) {
    return {
      projectName,
      noOfActionsDone,
      status,
      actualStartDate,
      actualEndDate,
      assignedTo,
    };
  }

  const ListTable_1 = [
    createData_1(
      "Health Circles",
      7,
      "High Priority",
      "24/08/2022",
      "24/08/2022",
      "Kiran Gems"
    ),
    createData_1(
      "Mind Body Food",
      3,
      "High Priority",
      "24/08/2022",
      "24/08/2022",
      "Swetha Thiru"
    ),
    createData_1(
      "Primary Care",
      12,
      "Low Priority",
      "24/08/2022",
      "24/08/2022",
      "Joseph Raj"
    ),
    createData_1(
      "Tanya Care",
      21,
      "Low Priority",
      "24/08/2022",
      "24/08/2022",
      "Helen Angel"
    ),
    createData_1(
      "Property Automate",
      5,
      "High Priority",
      "24/08/2022",
      "24/08/2022",
      "Mickel Johnson"
    ),
    createData_1(
      "IDM",
      6,
      "Low Priority",
      "24/08/2022",
      "24/08/2022",
      "Jonsnow Jack"
    ),
    createData_1(
      "Colabo",
      3,
      "Low Priority",
      "24/08/2022",
      "24/08/2022",
      "Joey Tribbiani"
    ),
    createData_1(
      "Automatly",
      16,
      "High Priority",
      "24/08/2022",
      "24/08/2022",
      "Chandler Bing"
    ),
  ];

  React.useEffect(() => {
    getTableData();
  }, []);
  const getTableData = () => {
    const payload = {
      page: "INTEGER",
      length: "INTEGER",
      startDate: "DATE",
      endDate: "DATE",
      timeZone: "TIMEZONEID",
    };

    NetworkCall(
      `${config.api_url}/reports/get`,
      NetWorkCallMethods.get,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        console.log("err", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <div>
      <Grid container className={classes.marginTop}>
        <Grid item xs={12}>
          <Box className={classes.headerBox}>
            <Subheader title="Total Reports (8)" filterIcon searchFilter />
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.reportTable}>
          <Grid item xs={12}>
            <TableWithPagination
              heading={Tableheading_1}
              rows={ListTable_1}
              path={Tablepath_1}
              showpagination
              dataType={[
                { type: ["text"], name: "projectName" },
                { type: ["text"], name: "noOfActionsDone" },
                { type: ["text"], name: "status" },
                { type: ["text"], name: "actualStartDate" },
                { type: ["text"], name: "actualEndDate" },
                { type: ["text"], name: "assignedTo" },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
