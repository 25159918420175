import * as React from "react"

const UserManagementSvg = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Group 93692"
        width={26}
        height={26}
        {...props}
    >
        <path
            d="M11.095 3a4.762 4.762 0 0 0 0 9.524 8.947 8.947 0 0 0 5.044-2.168 20.93 20.93 0 0 0 2.381-2.1.714.714 0 0 0 0-.978 20.93 20.93 0 0 0-2.381-2.1A8.947 8.947 0 0 0 11.095 3Zm0 1.429a8.214 8.214 0 0 1 4.182 1.88 12.539 12.539 0 0 1 1.648 1.454 12.539 12.539 0 0 1-1.648 1.454 8.214 8.214 0 0 1-4.182 1.88 3.334 3.334 0 0 1 0-6.667Zm6.19 8.1a4.907 4.907 0 0 0-.82.069l-.165.682a1.9 1.9 0 0 1-2.391 1.38l-.574-.17a5.286 5.286 0 0 0-.812 1.5l.426.409a1.906 1.906 0 0 1 0 2.746l-.426.41a5.276 5.276 0 0 0 .812 1.495l.574-.169a1.9 1.9 0 0 1 2.391 1.38l.165.682a4.962 4.962 0 0 0 .82.068 4.907 4.907 0 0 0 .82-.069l.165-.682a1.9 1.9 0 0 1 2.391-1.38l.574.17a5.286 5.286 0 0 0 .812-1.5l-.426-.409a1.906 1.906 0 0 1 0-2.746l.426-.409a5.281 5.281 0 0 0-.812-1.5l-.574.17a1.9 1.9 0 0 1-2.391-1.38l-.165-.682a4.907 4.907 0 0 0-.82-.07Zm-11.19 1.9a2.145 2.145 0 0 0-2.143 2.142v.571c0 2.75 3.347 4.905 7.619 4.905.409 0 .807-.026 1.2-.064a6.179 6.179 0 0 1-.973-1.371c-.075 0-.147.007-.223.007-3.823 0-6.19-1.8-6.19-3.477v-.571a.715.715 0 0 1 .714-.714h5.3a6.139 6.139 0 0 1 .678-1.429Zm11.19 1.9a1.429 1.429 0 1 1-1.433 1.433 1.428 1.428 0 0 1 1.433-1.429Z"
            fill="#848484"
        />
    </svg>
)

export default UserManagementSvg