import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { TextBox } from "../../components/textbox";
import { alertConfigStyles } from "./style";

export const PushNotifications = (props) => {
  const PushNotifications = [
    {
      label: "FCM Server Key",
    },
    {
      label: "FCM Project ID",
    },
    {
      label: "FCM Client Email",
    },
  ];

  const classes = alertConfigStyles();

  return (
    <Box className={classes.tabparent}>
      <Grid container>
        {PushNotifications?.map((e) => {
          return (
            <Grid item md={4} className={classes.notifyTextBox}>
              <TextBox label={e?.label} placeholder={false} />
            </Grid>
          );
        })}
        <Grid item md={12} className={classes.notifyinput} p={1} pt={2}>
          <TextBox
            label={"FCM Private Key"}
            placeholder={false}
            height="200px"
          />
        </Grid>
      </Grid>
      <Stack
        direction="row"
        justifyContent="space-between"
        className={classes.btns}
      >
        <Typography className={classes.btnone}>Cancel</Typography>
        <Typography className={classes.btn2}>Submit</Typography>
      </Stack>
    </Box>
  );
};
