import makeStyles from "@mui/styles/makeStyles";

export const SmallChartCardStyles = makeStyles((theme) => ({
  mainBox: {
    border: "1px solid" + theme.palette.border.tertiary,
    padding: "20px",
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "16px",
    },
  },

  secondLayer: {
    display: "flex",
    alignItems: "center",
  },

  titleText: {
    "& p": {
      fontSize: "14px",
      color: theme.typography.color.darkGrey,
      marginBottom: "5px",
      fontWeight: 600,
      textAlign: "start",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
  },

  valueText: {
    "& p": {
      fontSize: "24px",
      fontWeight: 900,
      textAlign: "start",
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
      },
    },
  },

  leftContent: {
    flex: 1,
  },
}));
