import React from "react";
import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Subheader } from "../../components/subHeader/SubHeader";
import { alertConfigStyles } from "./style";
import { EmailTab } from "./emailTab";
import { PushNotifications } from "./pushNotification";

export const AlertConfig = () => {
  const [index, setindex] = React.useState(0);

  const classes = alertConfigStyles();

  const alertConfigTab = ["Email", "SMS", "FCM/ Push Notification"];

  const handleAlertTab = (i) => {
    setindex(i);
  };
  return (
    <div className={classes.root}>
      <Subheader title="Alert Configurations" />
      <Box className={classes.referenceDiv} m={1} mb={2}>
        <Stack direction="row" className={classes.sampleTabBHead}>
          {alertConfigTab?.map((e, i) => {
            return (
              <Typography
                pt={2}
                pb={2}
                onClick={() => handleAlertTab(i)}
                className={
                  i === index
                    ? classes.alertConfigTabTxt
                    : classes.alertConfigTab
                }
              >
                {e}
              </Typography>
            );
          })}
        </Stack>
        <Box
          className={classes.referenceDiv2}
          mb={2}>
          <Box p={3} pl={4} pr={4} className={classes.tabDivs}>
            {index === 0 && <EmailTab emailInput={false} />}
            {index === 1 && <EmailTab emailInput={true} />}
            {index === 2 && <PushNotifications />}
          </Box>
        </Box>
      </Box>
    </div>
  );
};
