import * as React from "react";

const LockKey = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13.514}
    height={13.514}
    {...props}
  >
    <path
      data-name="icons8-key (1)"
      d="M8.891 0a4.632 4.632 0 0 0-4.623 4.623 4.414 4.414 0 0 0 .125.9L.156 9.759a.534.534 0 0 0-.156.377v2.845a.534.534 0 0 0 .533.533h2.846a.534.534 0 0 0 .533-.533v-.889h1.245a.534.534 0 0 0 .533-.533v-1.245h1.245a.534.534 0 0 0 .533-.533V9a4.562 4.562 0 0 0 1.423.251 4.624 4.624 0 0 0 0-9.247Zm0 1.067a3.556 3.556 0 1 1-1.7 6.681.534.534 0 0 0-.789.468v1.031H5.157a.534.534 0 0 0-.533.533v1.245H3.379a.534.534 0 0 0-.533.533v.889H1.067v-2.09l4.264-4.264a.534.534 0 0 0 .137-.52 3.545 3.545 0 0 1 3.424-4.506ZM9.6 2.845a1.067 1.067 0 1 0 1.067 1.067A1.067 1.067 0 0 0 9.6 2.845Z"
      fill="#10061d"
    />
  </svg>
);

export default LockKey;
