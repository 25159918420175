import makeStyles from "@mui/styles/makeStyles";

export const vendorStyles = makeStyles((theme) => ({
  firstBox: {
    background: "#ffff",
    boxShadow: theme.palette.primary.boxShadow,
    borderRadius: theme.palette.borderRadius,
    padding: "0"
  },
  headerBox: {
    paddingLeft:"20px"
  },
  root: {
    [theme.breakpoints.up("lg")]: {
      padding: "6% 0 1% 0 ",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "8% 0 1% 0 ",

    },
    [theme.breakpoints.down("md")]: {
      padding: "9% 0 1% 0 ",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "18% 0 1% 0 !important",
    },
  },
  tooltip: {
    backgroundColor: "#F2F4F7",
    border: "1px solid #E4E8EE",
    display: "flex",
    alignItems: "center",
    padding: "4px 7px",
    zIndex: 999,
    borderRadius: "4px",
    position: "relative",
  },
  smallFirstCard: {
    padding: "12px 12px 12px 0",
    "&:nth-child(4)": {
      paddingRight: "0"
    },
    [theme.breakpoints.between("md", "lg")]: {
      "&:nth-child(4)": {
        paddingRight: "12px"
      }
    },
    [theme.breakpoints.between("sm", "md")]: {
      "&:nth-child(2)": {
        paddingRight: "0"
      },
      "&:nth-child(4)": {
        paddingRight: "0"
      },
      "&:nth-child(6)": {
        paddingRight: "0"
      },
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0"
    },

  },
}));
