import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { AlertDialog } from "../../components/dialog";
import { Subheader } from "../../components/subHeader/SubHeader";
import { TableWithPagination } from "../../components/tableWithPagination";
import { config } from "../../config";
import { NetworkCall } from "../../networkCall";
import { LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import { Popup } from "./components/popup";
import { AddRuleStyles } from "./style";
import moment from "moment-timezone";

export const AlertRule = () => {
  const initialState = {
    alert_code: "",
    reference_id: "",
    hashtags: "",
    description: "",
    isActive: false,
    is_email: false,
    is_push: false,
    is_sms: false,
  };

  const classes = AddRuleStyles();
  const [open, setOpen] = React.useState(false);
  const [alertRow, setAlertRow] = React.useState([]);
  const [alertRule, setAlertRule] = React.useState({ ...initialState });

  const updateState = (key, value) => {
    setAlertRule({
      ...alertRule,
      [key]: value,
    });
  };

  const handleClick = () => {
    setAlertRule({ ...initialState });
    setOpen(true);
  };

  const handleClose = () => {
    setAlertRule({ ...initialState });
    setOpen(false);
  };

  const handleSubmit = () => {
    const payload = {
      profileId: alertRule?.data?.profileId,
      alert_rule_code: alertRule?.alert_code,
      reference_id: alertRule?.reference_id,
      hashtag: alertRule?.hashtags,
      description: alertRule?.description,
      is_email: alertRule?.is_email,
      is_sms: alertRule?.is_sms,
      is_push: alertRule?.is_push,
      email_subject: alertRule?.data?.email_subject,
      email_body: alertRule?.data?.email_body,
      email_CC: "-",
      email_BCC: "-",
      from_mail: "-",
      sms_body: alertRule?.data?.sms_body,
      push_title: alertRule?.data?.push_title,
      push_body: alertRule?.data?.push_body,
      isActive: alertRule?.isActive,
    };

    NetworkCall(
      `${config.api_url}/rules/upsert`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        console.log(res);
        getTableData();
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Table - 1
  const Tablepath_1 = [
    "addRuleCode",
    "referenceId",
    "hashtags",
    "description",
    "emailSubject",
    "smsBody",
    "pushTitle",
    "isActive",
    "createdAt",
    "action",
  ];

  const Tableheading_1 = [
    { title: "Alert Rule Code", field: "addRuleCode" },
    { title: "Reference ID", field: "referenceId" },
    { title: "Hashtags", field: "hashtags" },
    { title: "Description", field: "description" },
    { title: "Email Subject", field: "emailSubject" },
    { title: "SMS Body", field: "smsBody" },
    { title: "Push Title", field: "pushTitle" },
    { title: "is Active", field: "isActive" },
    { title: "Created At", field: "createdAt" },
    { title: "Action", field: "action" },
  ];

  function createData_1(
    addRuleCode,
    referenceId,
    description,
    emailSubject,
    isActive,
    createdAt,
    hashtags,
    smsBody,
    pushTitle,
    action
  ) {
    return {
      addRuleCode,
      referenceId,
      description,
      emailSubject,
      isActive,
      createdAt,
      hashtags,
      smsBody,
      pushTitle,
      action,
    };
  }

  React.useEffect(() => {
    getTableData();
  }, []);
  const getTableData = () => {
    const payload = {
      profileId: localStorage.getItem(LocalStorageKeys.profileId),
    };

    NetworkCall(
      `${config.api_url}/rules/get`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const tempRow = res?.data?.map((i) => {
          return {
            addRuleCode: i?.alert_rule_code ? i?.alert_rule_code : "-",
            referenceId: i?.reference_id ? i?.reference_id : "-",
            hashtags: i?.hashtag ? i?.hashtag : "-",
            description: i?.description ? i?.description : "-",
            emailSubject: i?.email_subject ? i?.email_subject : "-",
            smsBody: i?.sms_body ? i?.sms_body : "-",
            pushTitle: i?.push_title ? i?.push_title : "-",
            isActive: i?.isActive ? i?.isActive : "-",
            createdAt: i?.createdAt
              ? moment(i?.createdAt).tz(moment.tz.guess()).format("MMM DD YYYY")
              : "-",
            data: i,
          };
        });
        setAlertRow(tempRow);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleIcon = (type, data) => {
    setAlertRule({
      ...alertRule,
      alert_code: data?.data?.alert_rule_code,
      reference_id: data?.data?.reference_id,
      hashtags: data?.data?.hashtag,
      description: data?.data?.description,
      isActive: data?.data?.isActive,
      is_email: data?.data?.is_email,
      is_push: data?.data?.is_push,
      is_sms: data?.data?.is_sms,
      data: data?.data,
    });
    setOpen(true);
  };

  return (
    <div>
      <Grid container className={classes.marginTop}>
        <Grid item xs={12}>
          <Box className={classes.headerBox}>
            <Subheader
              title="Alert Rule (12)"
              searchFilter
              addIcon
              handleClick={handleClick}
            />
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.reportTable}>
          <Grid item xs={12}>
            <TableWithPagination
              heading={Tableheading_1}
              rows={alertRow}
              path={Tablepath_1}
              showpagination
              handleIcon={handleIcon}
              dataType={[
                { type: ["text"], name: "addRuleCode" },
                { type: ["text"], name: "referenceId" },
                { type: ["hashtags"], name: "hashtags" },
                { type: ["text"], name: "description" },
                { type: ["text"], name: "emailSubject" },
                { type: ["smsBody"], name: "smsBody" },
                { type: ["pushTitle"], name: "pushTitle" },
                { type: ["isActive"], name: "isActive" },
                { type: ["text"], name: "createdAt" },
                { type: ["action_edit"], name: "action" },
              ]}
            />
            <AlertDialog
              open={open}
              onClose={() => setOpen(!open)}
              header="Add Alert Rule"
              component={
                <Popup
                  data={alertRule}
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                  updateState={updateState}
                />
              }
              medium
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
