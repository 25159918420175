import makeStyles from "@mui/styles/makeStyles";

export const LoginScreenStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F9F9F9",
    height: "100vh",
  },

  cardBox: {
    padding: "40px",
    borderRadius: "16px !important",
    [theme.breakpoints.down("sm")]: {
      padding: "90px",
    }
  },

  contentText: {
    textAlign: "center",
  },

  logo: {
    "& img": {
      width: "62px",
      height: "62px",
    },
  },

  signText: {
    "& p": {
      fontSize: "24px",
      fontWeight: "bold",
      marginTop: "13px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "21px",
      }
    },

  },

  bottomText: {
    "& p": {
      fontSize: "12px",
      fontWeight: 500,
      marginTop: "13px",
      color: "#848484",
      [theme.breakpoints.down("sm")]: {
        fontSize: "11px",
      }
    },
  },

  formContent: {
    marginTop: "24px",
    "& p": {
      fontSize: "12px",
      color: "#848484",
      marginBottom: "4px",
    },
  },

  password: {
    marginTop: "16px",
  },

  passwordField: {
    display: "flex",
    justifyContent: "space-between",
  },

  passwordText: {
    "& p": {
      "& span": {
        color: "red",
      },
    },
  },

  forgotPassword: {
    "& p": {
      color: "#464775",
    },
  },

  signBtn: {
    padding: "11px 0",
    color: "#FFFFFF !important",
    backgroundColor: "#464775",
    borderRadius: "8px !important",
    textTransform: "capitalize !important",
    "&:hover": {
      color: "#FFFFFF !important",
      backgroundColor: "#464775",
    },
  },

  cardAction: {
    padding: "16px !important",
  },

  outlineForm: {
    width: "100%",
  },

  outlineInput: {
    borderRadius: "8px !important",
    "& input": {
      padding: "11px 16px !important",
    },
  },
}));
