import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import DarkBlueChart from "../../assets/darkBlueChart";
import LightBlueChart from "../../assets/lightBlueChart";
import GreenChart from "../../assets/greenChart";
import OrangeChart from "../../assets/orangeChart";
import PurpleChart from "../../assets/purpleChart";
import YellowChart from "../../assets/yellowChart";
import { GraphComp } from "../../components/graph/graph";
import { SmallChartCard } from "../../components/smallChartCard";
import { Subheader } from "../../components/subHeader/SubHeader";
import { vendorStyles } from "./style";

export const TotalVendors = (props) => {
  const classes = vendorStyles();

  const vendorCards = [
    {
      label: "Total Requests",
      value: "56",
      svg: (<GreenChart />)
    },
    {
      label: "Total Bounces",
      value: "14",
      svg: (<YellowChart />)

    },
    {
      label: "Total Delivered",
      value: "11",
      svg: (<DarkBlueChart />)

    },
    {
      label: "Opened",
      value: "34",
      svg: (<LightBlueChart />)

    },
    {
      label: "Clicked",
      value: "12",
      svg: (<PurpleChart />)

    },
    {
      label: "Spam Reports",
      value: "0",
      svg: (<OrangeChart />)

    }

  ]

  return (
    <div className={classes.root}>
      <Box className={classes.firstBox} m={2} mt={3} pb={2}>
        <Box className={classes.headerBox}>
          <Subheader title="Total Vendors (8)" calendar />
        </Box>
        <Grid container m={0} pl={3} pr={3}>
          {vendorCards?.map(e => {
            return (
              <Grid item lg={3} md={4} sm={6} xs={12} className={classes.smallFirstCard} pl={0} >
                <SmallChartCard
                  title={e?.label}
                  value={e?.value}
                  element={e?.svg}
                />

              </Grid>
            )
          })}
          {/* <Grid item lg={3} className={classes.smallCard}>
            <SmallChartCard
              title="Total Bounces"
              value="14"
              element={<YellowChart />}
            />
          </Grid>
          <Grid item lg={3} className={classes.smallCard}>
            <SmallChartCard
              title="Total Delivered"
              value="11"
              element={<DarkBlueChart />}
            />
          </Grid>
          <Grid item lg={3} className={classes.smallCard}>
            <SmallChartCard
              title="Opened"
              value="34"
              element={<LightBlueChart />}
            />
          </Grid>
          <Grid item lg={3} className={classes.smallFirstCard}>
            <SmallChartCard
              title="Clicked"
              value="12"
              element={<PurpleChart />}
            />
          </Grid>
          <Grid item lg={3} className={classes.smallCard}>
            <SmallChartCard
              title="Spam Reports"
              value="0"
              element={<OrangeChart />}
            />
          </Grid> */}
        </Grid>
      </Box>
      <Box className={classes.firstBox} p={2} m={2} mt={3}>
        <Subheader title="Report" calendar />
        <GraphComp />
      </Box>
    </div>
  );
};
