import {
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { TextBox } from "../../components/textbox";
import { config } from "../../config";
import { NetworkCall } from "../../networkCall";
import { NetWorkCallMethods } from "../../utils";
import { alertConfigStyles } from "./style";

export const EmailTab = (props) => {
  const classes = alertConfigStyles();

  const [emailContent, setemailContent] = React.useState({});

  React.useEffect(() => {
    NetworkCall(
      `${config.api_url}/config/mail/getAll`,
      NetWorkCallMethods.get,
      null,
      null,
      true,
      false
    ).then(res => {
      setemailContent(res?.data?.data?.rows?.[0])
    })
  }, [])

  return (
    <Box className={classes.tabparent}>
      <Box>

        <Box pt={1} pb={1}>
          <TextBox value={emailContent?.email_provider} label="Provider" placeholder="https://alertshub-api.crayond.com/api/v1/sendmessage" 
          // onChange={()=>}
          />
        </Box>
        <Box pt={1} pb={1}>
          <TextBox value={emailContent?.api_key} label="API key" placeholder="https://alertshub-api.crayond.com/api/v1/sendmessage" />
        </Box>

        {/* <Box className={classes.checkBox} pt={1} pb={1}>
          <FormControlLabel control={<Checkbox />} label="Set as default" />
        </Box>
        {!Boolean(props.emailInput) && (
          <Grid container className={classes.mailInputParent}>
            {emailContent?.map((e) => {
              return (
                <>
                  <Grid item md={6} pt={1} pb={1} className={classes.mailInput}>
                    <TextBox label={e?.label} placeholder={false} />
                  </Grid>
                </>
              );
            })}
          </Grid>
        )} */}
      </Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        mb={2}
        className={classes.btns}
      >
        <Typography className={classes.btnone}>Cancel</Typography>
        <Typography className={classes.btn2}>Submit</Typography>
      </Stack>
    </Box>
  );
};
