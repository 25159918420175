import React from "react";
import withNavBars from "../../HOCs/withNavBars";
import { TotalVendors } from "./totalvendors";

class LoginParent extends React.Component {
  render() {
    return <TotalVendors />;
  }
}

export default withNavBars(LoginParent);
