import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { SmallChartCardStyles } from "./style";

export const SmallChartCard = ({ title = "", value = "", element = "" }) => {
  const classes = SmallChartCardStyles();
  return (
    <Box className={classes.mainBox}>
      <div className={classes.secondLayer}>
        <div className={classes.leftContent}>
          <div className={classes.titleText}>
            <Typography>{title}</Typography>
          </div>
          <div className={classes.valueText}>
            <Typography>{value}</Typography>
          </div>
        </div>
        <div className={classes.rightContent}>{element}</div>
      </div>
    </Box>
  );
};
