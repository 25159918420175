import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: 240,
    position: "fixed",
    zIndex: "11",
    top: "0",
  },
  drawer: {
    height: `100vh`,
    width: 240,
  },
  drawer1: {
    width: 60,
    height: `100vh`,
  },
  drawerContainer: {
    overflow: "hidden",
  },
  logo1: {
    width: "63px",
    height: "63px",
    margin: "20px auto",
  },
  logo2: {
    width: "33px",
    height: "33px",
    margin: "20px auto",
  },
  navitems: {
    color: "#848484",
    fontSize: 15,
  },
  navLink: {
    textDecoration: "none !Important",
    cursor: "pointer",
  },
  navItem: {
    background: "#fff",
    paddingLeft: "24px",
    cursor: "pointer",
  },
  navItemClick: {
    borderLeft: `3px solid ${theme.typography.color.blue}`,
    background: "#0000001F",
    paddingLeft: "24px",
    cursor: "pointer",
  },
}));
