import makeStyles from "@mui/styles/makeStyles";

export const alertConfigStyles = makeStyles((theme) => ({
  root: {
    margin: "16px",
    [theme.breakpoints.up("lg")]: {
      padding: "6% 0 1% 0 ",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "8% 0 1% 0 ",

    },
    [theme.breakpoints.down("md")]: {
      padding: "9% 0 1% 0 ",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "18% 0 1% 0 !important",
    },
  },
  alertConfigTab: {
    cursor: "pointer",
    fontSize: "16px",
    fontFamily: "crayond_medium",
    "&:nth-child(1)": {
      marginLeft: "30px ",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "20px",
      },
    },
    "&:nth-child(2)": {
      margin: "0 60px ",
      [theme.breakpoints.down("sm")]: {
        margin: "0 20px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
      paddingTop: "12px",
      paddingBottom: "12px",
    },
  },
  alertConfigTabTxt: {
    cursor: "pointer",
    fontSize: "16px",
    fontFamily: "crayond_medium",
    color: theme.typography.color.blue,
    borderBottom: `3px solid ${theme.typography.color.blue}`,
    "&:nth-child(1)": {
      marginLeft: "30px ",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "20px",
      },
    },
    "&:nth-child(2)": {
      margin: "0 60px",
      [theme.breakpoints.down("sm")]: {
        margin: "0 20px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
      paddingTop: "12px",
      paddingBottom: "12px",
    },
  },
  referenceDiv: {
    backgroundColor: theme.typography.color.white,
    borderRadius: theme.palette.borderRadius,
    boxShadow: theme.palette.primary.boxShadow,
  },
  sampleTabBHead: {
    backgroundColor: theme.typography.color.voilet,
    borderTopLeftRadius: theme.palette.borderRadius,
    borderTopRightRadius: theme.palette.borderRadius,
  },
  checkBox: {
    "& label": {
      color: theme.typography.color.darkGrey,
      "& span ": {
        "&:nth-child(1)": {
          color: "#28c397 !important",
        },
      },

    },
  },
  btns: {
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      marginTop:"12px"
    },
  },
  btnone: {
    color: theme.typography.color.red,
    border: `1px solid ${theme.typography.color.darkGrey}`,
    padding: "10px 14px",
    borderRadius: "8px",
    fontSize: "14px",
  },
  btn2: {
    color: theme.typography.color.white,
    backgroundColor: theme.typography.color.blue,
    border: `1px solid ${theme.typography.color.darkGrey}`,
    padding: "10px 20px",
    borderRadius: "8px",
    fontSize: "14px",
    marginLeft: "10px"
  },
  notifyTextBox: {
    padding: "0 8px",
  },
  notifyinput: {
    "& div": {
      "& div": {
        "& div": {
          height: "80px !important",
        },
      },
    },
  },
  mailInputParent: {
    "& div": {
      "&:nth-child(1)": {
        [theme.breakpoints.down("sm")]: {
          paddingRight: "0px"
        },
      },
      [theme.breakpoints.down("md")]: {
        flexGrow: "1"
      },
    },
    "& div": {
      [theme.breakpoints.down("md")]: {
        flexGrow: "1"
      },
    },
  },
  mailInput: {
    paddingRight: "16px",
    "&:nth-child(2)": {
      paddingRight: "0px"
    },
    [theme.breakpoints.down("sm")]: {
      "&:nth-child(1)": {
        paddingLeft: "0px"
      },
    },
  },
  tabparent: {
    height: "100% ",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  tabDivs: {
    height: "100%",
    padding: "24px 32px",
    [theme.breakpoints.down("sm")]: {
      padding: "16px 20px"
    },
  },
  referenceDiv2 :{
    backgroundColor: theme.typography.color.white,
    borderRadius: theme.palette.borderRadius,
    boxShadow: theme.palette.primary.boxShadow,
    height:"550px",
    [theme.breakpoints.down("sm")]: {
      height:"auto",
    },
  } 
}));
