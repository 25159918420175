import React from "react";
import withNavBars from "../../HOCs/withNavBars";
import { AlertRule } from "./alertRule";

class AddRuleParent extends React.Component {
  render() {
    return <AlertRule />;
  }
}

export default withNavBars(AddRuleParent);
