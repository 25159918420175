import * as React from "react"

const AlertTemplateSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Group 93692"
    width={26}
    height={26}
    {...props}
  >
    <path
      d="M6.421 4a2.425 2.425 0 0 0-2.312 1.7 2.4 2.4 0 0 0-.1.471 2.446 2.446 0 0 0-.009.25v11a2.424 2.424 0 0 0 2.421 2.421h6.408a5.69 5.69 0 0 1-.626-1.321H6.421a1.1 1.1 0 0 1-1.1-1.1V7.962h14.085v3.361a5.675 5.675 0 0 1 1.321.627V6.421a2.429 2.429 0 0 0-.013-.248 2.4 2.4 0 0 0-.1-.471A2.425 2.425 0 0 0 18.306 4Zm0 1.321a.661.661 0 1 1-.133.014.661.661 0 0 1 .133-.014Zm2.2 0a.66.66 0 1 1-.66.66.661.661 0 0 1 .661-.66Zm9.024 6.6a4.536 4.536 0 0 0-.758.064l-.152.63a1.76 1.76 0 0 1-2.21 1.276l-.53-.157a4.886 4.886 0 0 0-.751 1.382l.394.378a1.762 1.762 0 0 1 0 2.538l-.394.379a4.877 4.877 0 0 0 .751 1.382l.53-.156a1.76 1.76 0 0 1 2.21 1.276l.152.63a4.587 4.587 0 0 0 .758.063 4.536 4.536 0 0 0 .758-.064l.152-.63a1.76 1.76 0 0 1 2.21-1.276l.53.157a4.887 4.887 0 0 0 .751-1.382l-.394-.378a1.762 1.762 0 0 1 0-2.538l.394-.378a4.882 4.882 0 0 0-.751-1.382l-.53.157a1.76 1.76 0 0 1-2.21-1.276l-.152-.63a4.536 4.536 0 0 0-.757-.063Zm0 3.521a1.321 1.321 0 1 1-1.321 1.321 1.32 1.32 0 0 1 1.322-1.318Z"
      fill="#848484"
    />
  </svg>
)

export default AlertTemplateSvg
