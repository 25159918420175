import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { MenuItems } from "./menuItems";
import { useStyles } from "./style";

export const SideNavBar = (props) => {
  const [Close, setClose] = React.useState(false);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles(props);

  React.useEffect(() => {
    if (matches) {
      setClose(true);
    } else {
      setClose(false);
    }
  }, [matches]);

  return (
    <div className={classes.root}>
      <MenuItems Close={Close} setClose={setClose} matches={matches} />
    </div>
  );
};
