import makeStyles from "@mui/styles/makeStyles";

export const TotalUsersStyles = makeStyles((theme) => ({
  marginTop_2: {
    marginTop: "71px",
  },
  headerBox: {
    paddingLeft: "20px"
  },
  userTable: {
    padding: "0px 24px 24px 24px",
  },
}));
