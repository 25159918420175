/**
 * Object with role as key and value, which is used for
 * comparison of role in different place.
 */
export const UserRoles = {
  role: "role",
};

/**
 * Object which has the proper name of all the role
 * used in the application.
 */
export let UserRolesName = {
  role: "Role",
};

/**
 * Object which has the different themes used in
 * the application.
 */
export let Themes = {
  default: "default",
  dark: "dark",
};

/**
 * Object which has the different props for the Alert Component (/src/component/alert)
 * which is used via AlertContext (/src/contexts) and provided at /src/App.alert.js.
 */
export let AlertProps = {
  vertical: {
    top: "top",
    bottom: "bottom",
  },
  horizontal: {
    left: "left",
    right: "right",
    center: "center",
  },
  severity: {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  },
};

/**
 * Object which has the different props for the Drawer Component (/src/App.drawer.js)
 * which is used via DrawerContext (/src/contexts) and provided at /src/App.drawer.js.
 */
export const DrawerProps = {
  direction: {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right",
  },
  variant: {
    permanent: "permanent",
    persistent: "persistent",
    temporary: "temporary",
  },
};

/**
 * Object has the key and value pair of all the keys which
 * are used to store some values in the local storage.
 */
export let LocalStorageKeys = {
  profileId: "profileId",
  authToken: "auth_token",
  version: "version",
};

// EnumName
export let enumName = {
  unitCategory: "unit_category",
  userDefined: "User Defined",
  rentalValue: "Rental Value",
  percentage: "Percentage",
  perUOM: "Per UOM",
  daily: "Daily",
  residential: "Residential",
  commercial: "Commercial",
  storage: "Storage",
  mixed: "Mixed",
  advertisingSpace: "Advertising Space",
  commercialUnit: "Commercial Unit",
  coWorking: "Co-working",
  communityUnit: "Community Unit",
  residentialUnit: "Residential Unit",
  parkingUnit: "Parking Unit",
  storageSpace: "Storage Space",
  revenue_base: "Revenue based lease",
  bedSpace: "Bed Space",
  sale: "Sale",
  land: "Land",
  plot: "Plot",
  singleUnit: "Single Unit",
  multiUnit: "Multi-Unit",
  multiUnitFloor: "Multi-Unit by Floors",
  multiUnitBlockFloor: "Multi-Unit by Blocks",
  storageUnitStorageBin: "Multi-Unit by Storage Bin",
  storageUnitStorageTypeStorageBin: "Multi-Unit by Storage Type",
  pending: "Pending",
  monthly: "Monthly",
  renewal: "Renewal",
  termination: "Termination",
  rejected: "Rejected",
  cancelled: "Cancelled",
  approved: "Approved",
  propertyOwner: "Property Owner",
  milestoneBased: "Milestone Based",
  onCompletion: "On Completion",
  weekly: "Weekly",
  prepaid: "Prepaid",
  hourly: "Hourly",
  yearly: "Yearly",
  halfYearly: "Half Yearly",
  quarterly: "Quarterly",
  manage: "Manage",
  lease: "Lease",
};

// To build version string
export const VersionFetchURL = `${window.location.protocol}//${window.location.hostname
  }${window.location.port ? ":" + window.location.port : ""
  }/meta.json?v=${+new Date()}`;

export let NetWorkCallMethods = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
  update: "UPDATE",
};
