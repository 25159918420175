import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  header: {
    borderRadius: "8px",
    padding: "24px 24px 16px 8px",
    [theme.breakpoints.down("sm")]: {
      padding: "16px 16px",
    },
  },
  headerFlex: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  title: {
    color: "#10061D",
    // fontSize: "19px",
    fontFamily: "crayond_medium",
    [theme.breakpoints.up("lg")]: {
      fontSize: "19px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "17px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      textAlign: "center",
    },
  },
  buttontext: {
    borderRadius: 5,
    textTransform: "capitalize",
    backgroundColor: "red",
    padding: "3px 6px",
  },
  buttontextClick: {
    borderRadius: 5,
    textTransform: "capitalize",
    backgroundColor: "#FFFFFF",
    padding: "3px 20px",
    color: "#10061D !important",
  },
  calbtnGrp: {
    backgroundColor: "#e9e9ee",
    cursor: "pointer",
    borderRadius: 5,
    padding: "3px 4px",
    width: "180px",
    height: "38px",
    justifyContent: "space-between",
    "& div": {
      "& p": {
        fontSize: "12px",
        color: "#848484",
        padding: "6px 20px",
      },
    },
  },
  calendarSm: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      marginTop: "16px",
    },
  },
  filterIconBox: {
    boxShadow: theme.palette.primary.boxShadow,
    backgroundColor: theme.typography.color.white,
    width: "38px",
    height: "38px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  addIconBox: {
    backgroundColor: theme.typography.color.blue,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    cursor: "pointer",
    boxShadow: theme.palette.primary.boxShadow,
  },
}));
