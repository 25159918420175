import React from "react";
import withNavBars from "../../HOCs/withNavBars";
import { TotalUsers } from "./totalUsers";

class TotalUsersParent extends React.Component {
  render() {
    return <TotalUsers />;
  }
}

export default withNavBars(TotalUsersParent);
