import { Grid } from "@mui/material";
import React from "react";
import DarkBlueChart from "../../assets/darkBlueChart";
import GreenChart from "../../assets/greenChart";
import LightBlueChart from "../../assets/lightBlueChart";
import OrangeChart from "../../assets/orangeChart";
import PurpleChart from "../../assets/purpleChart";
import YellowChart from "../../assets/yellowChart";
import { SmallChartCard } from "../../components/smallChartCard";
import { TableWithPagination } from "../../components/tableWithPagination";

export const ChartCardScreen = () => {
  // Table - 1
  const Tablepath_1 = [
    "projectName",
    "noOfActionsDone",
    "status",
    "actualStartDate",
    "actualEndDate",
    "assignedTo",
  ];

  const Tableheading_1 = [
    { title: "Project Name", field: "projectName" },
    { title: "No. Of Actions Done", field: "noOfActionsDone" },
    { title: "Status", field: "status" },
    { title: "Actual Start Date", field: "actualStartDate" },
    { title: "Actual End Date", field: "actualEndDate" },
    { title: "Assigned To", field: "assignedTo" },
  ];

  function createData_1(
    projectName,
    noOfActionsDone,
    status,
    actualStartDate,
    actualEndDate,
    assignedTo
  ) {
    return {
      projectName,
      noOfActionsDone,
      status,
      actualStartDate,
      actualEndDate,
      assignedTo,
    };
  }

  const ListTable_1 = [
    createData_1(
      "Health Circles",
      7,
      "High Priority",
      "24/08/2022",
      "24/08/2022",
      "Kiran Gems"
    ),
    createData_1(
      "Mind Body Food",
      3,
      "High Priority",
      "24/08/2022",
      "24/08/2022",
      "Swetha Thiru"
    ),
    createData_1(
      "Primary Care",
      12,
      "Low Priority",
      "24/08/2022",
      "24/08/2022",
      "Joseph Raj"
    ),
    createData_1(
      "Tanya Care",
      21,
      "Low Priority",
      "24/08/2022",
      "24/08/2022",
      "Helen Angel"
    ),
    createData_1(
      "Property Automate",
      5,
      "High Priority",
      "24/08/2022",
      "24/08/2022",
      "Mickel Johnson"
    ),
    createData_1(
      "IDM",
      6,
      "Low Priority",
      "24/08/2022",
      "24/08/2022",
      "Jonsnow Jack"
    ),
    createData_1(
      "Colabo",
      3,
      "Low Priority",
      "24/08/2022",
      "24/08/2022",
      "Joey Tribbiani"
    ),
    createData_1(
      "Automatly",
      16,
      "High Priority",
      "24/08/2022",
      "24/08/2022",
      "Chandler Bing"
    ),
  ];

  // Table - 2

  const Tablepath_2 = ["projectName", "apiKeyId", "apiKey", "action"];

  const Tableheading_2 = [
    { title: "Project Name", field: "projectName" },
    { title: "API Key ID", field: "apiKeyId" },
    { title: "API Key", field: "apiKey" },
    { title: "Action", field: "action" },
  ];

  function createData_2(projectName, apiKeyId, apiKey, action) {
    return {
      projectName,
      apiKeyId,
      apiKey,
      action,
    };
  }

  const ListTable_2 = [
    createData_2("Health Circles", "567yugf49plp2wew5gdu"),
    createData_2("Mind Body Food", "567yugf49plp2wew5gdu"),
    createData_2("Primary Care", "567yugf49plp2wew5gdu"),
    createData_2("Tanya Care", "567yugf49plp2wew5gdu"),
    createData_2("Property Automate", "567yugf49plp2wew5gdu"),
    createData_2("IDM", "567yugf49plp2wew5gdu"),
    createData_2("Colabo", "567yugf49plp2wew5gdu"),
    createData_2("Automatly", "567yugf49plp2wew5gdu"),
  ];

  // Table - 3

  const Tablepath_3 = [
    "userName",
    "userId",
    "role",
    "emailId",
    "contactNumber",
    "status",
  ];

  const Tableheading_3 = [
    { title: "User Name", field: "userName" },
    { title: "User ID", field: "userId" },
    { title: "Role", field: "role" },
    { title: "Email ID", field: "emailId" },
    { title: "Contact Number", field: "contactNumber" },
    { title: "Status", field: "status" },
  ];

  function createData_3(
    userName,
    userId,
    role,
    emailId,
    contactNumber,
    status
  ) {
    return {
      userName,
      userId,
      role,
      emailId,
      contactNumber,
      status,
    };
  }

  const ListTable_3 = [
    createData_3(
      "Kiran Gems",
      675432,
      "Manager",
      "harini@gmail.com",
      "+91 8989765433",
      "Active"
    ),
    createData_3(
      "Swetha Thiru",
      675432,
      "Manager",
      "swetha@gmail.com",
      "+91 8989765433",
      "Active"
    ),
    createData_3(
      "Joseph Raj",
      675432,
      "Manager",
      "rajjospeh@gmail.com",
      "+91 8989765433",
      "Inactive"
    ),
    createData_3(
      "Helen Angel",
      675432,
      "Manager",
      "helen@yahoo.com",
      "+91 8989765433",
      "Active"
    ),
    createData_3(
      "Mickel Johnson",
      675432,
      "Manager",
      "mike@gmail.com",
      "+91 8989765433",
      "Inactive"
    ),
    createData_3(
      "Jonsnow Jack",
      675432,
      "Manager",
      "jonsnow@gmail.com",
      "+91 8989765433",
      "Active"
    ),
    createData_3(
      "Joey Tribbiani",
      675432,
      "Manager",
      "joeyknowsnothing@gmail.com",
      "+91 8989765433",
      "Active"
    ),
    createData_3(
      "Chandler Bing",
      675432,
      "Manager",
      "chandlerbing890543@gmail.com",
      "+91 8989765433",
      "Inactive"
    ),
  ];

  // Table - 4

  const Tablepath_4 = ["parameter", "type", "description"];

  const Tableheading_4 = [
    { title: "Parameter", field: "parameter" },
    { title: "Type", field: "type" },
    { title: "Description", field: "description" },
  ];

  function createData_4(parameter, type, description) {
    return {
      parameter,
      type,
      description,
    };
  }

  const ListTable_4 = [
    createData_4("reference_id", "String", "Unique Representation of a alert"),
    createData_4(
      "push_receivers",
      "Array",
      "List of push notification receivers"
    ),
    createData_4(
      "push_title",
      "Array",
      "List of data parameters for push title"
    ),
    createData_4(
      "push_body",
      "Object",
      "List of data parameters for push body"
    ),
    createData_4(
      "push_click_action",
      "String",
      "URL to open site when clicking a received push notification"
    ),
    createData_4(
      "push_icon",
      "String",
      "Pass an image URL to set as an icon to the push notification"
    ),
    createData_4("to_mobiles", "Array", "List of mobile numbers to send SMS"),
    createData_4(
      "sms_body",
      "Array",
      "sms_boList of data parameters for sms bodydy"
    ),
  ];

  return (
    <div>
      <Grid container style={{ padding: "20px" }}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <SmallChartCard
                title="Total Requests"
                value="56"
                element={<GreenChart />}
              />
            </Grid>
            <Grid item xs={3}>
              <SmallChartCard
                title="Total Bounces"
                value="14"
                element={<YellowChart />}
              />
            </Grid>
            <Grid item xs={3}>
              <SmallChartCard
                title="Total Delivered"
                value="11"
                element={<DarkBlueChart />}
              />
            </Grid>
            <Grid item xs={3}>
              <SmallChartCard
                title="Opened"
                value="34"
                element={<LightBlueChart />}
              />
            </Grid>
            <Grid item xs={3}>
              <SmallChartCard
                title="Clicked"
                value="12"
                element={<PurpleChart />}
              />
            </Grid>
            <Grid item xs={3}>
              <SmallChartCard
                title="Spam Reports"
                value="0"
                element={<OrangeChart />}
              />
            </Grid>
          </Grid>
          <Grid></Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "20px" }}>
          <TableWithPagination
            heading={Tableheading_1}
            rows={ListTable_1}
            path={Tablepath_1}
            dataType={[
              { type: ["text"], name: "projectName" },
              { type: ["text"], name: "noOfActionsDone" },
              { type: ["text"], name: "status" },
              { type: ["text"], name: "actualStartDate" },
              { type: ["text"], name: "actualEndDate" },
              { type: ["text"], name: "assignedTo" },
            ]}
          />
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "20px" }}>
          <TableWithPagination
            heading={Tableheading_2}
            rows={ListTable_2}
            path={Tablepath_2}
            dataType={[
              { type: ["text"], name: "projectName" },
              { type: ["text"], name: "apiKeyId" },
              { type: ["api_key"], name: "apiKey" },
              { type: ["action_copy"], name: "action" },
            ]}
          />
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "20px" }}>
          <TableWithPagination
            heading={Tableheading_3}
            rows={ListTable_3}
            path={Tablepath_3}
            showpagination
            dataType={[
              { type: ["text"], name: "userName" },
              { type: ["text"], name: "userId" },
              { type: ["text"], name: "role" },
              { type: ["text"], name: "emailId" },
              { type: ["text"], name: "contactNumber" },
              { type: ["status_alert"], name: "status" },
            ]}
          />
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "20px" }}>
          <TableWithPagination
            heading={Tableheading_4}
            rows={ListTable_4}
            path={Tablepath_4}
            dataType={[
              { type: ["text"], name: "parameter" },
              { type: ["text"], name: "type" },
              { type: ["text"], name: "description" },
            ]}
          />
        </Grid>
      </Grid>
    </div>
  );
};
