import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  Label: {
    color: theme.typography.color.tertiary,
    fontSize: (props) => props?.labelSize ?? "14px",
    marginBottom: "12px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px ",

    },
  },
  text: {
    "& input": {
      padding: "11px 16px !important",
    },
  },
  required: {
    color: "red",
    fontSize: (props) => props?.labelSize ?? "14px",
    marginBottom: (props) => props?.labelMarginBottom ?? "5px",
  },
  textbox: {
    backgroundColor: (props) => props?.color ?? "auto",
    borderRadius: "8px",
    border: "none",
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
      backgroundColor: "#fff",
    },
    [`& fieldset`]: {
      borderRadius: "8px",
      height: (props) => (props.multiline ? "unset" : props?.height ?? 50),
      border: (props) => props?.border ?? "1px solid #CED3DD",
      "&:hover": {
        border: "1px solid red",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: (props) => props?.padding ?? "11px 14px",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#5078E1",
      },
    },
  },
}));
