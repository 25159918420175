import * as React from "react"

const LogoutSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Group 93692"
    width={26}
    height={26}
    {...props}
  >
    <path
      d="M7.085 4.127a2.973 2.973 0 0 0-2.958 2.958v11.831a2.973 2.973 0 0 0 2.958 2.958h8.38a3 3 0 0 0 2.927-2.219.986.986 0 1 0-1.91-.491 1.1 1.1 0 0 1-1.017.738h-8.38a.972.972 0 0 1-.986-.986V7.085a.972.972 0 0 1 .986-.986h8.38a1.1 1.1 0 0 1 1.017.738.986.986 0 1 0 1.91-.491 3 3 0 0 0-2.927-2.219Zm10.835 4.92a.986.986 0 0 0-.687 1.693l1.275 1.275H9.55a.986.986 0 1 0 0 1.972h8.958l-1.275 1.275a.986.986 0 1 0 1.394 1.393l2.958-2.958a.986.986 0 0 0 0-1.394l-2.958-2.958a.986.986 0 0 0-.708-.298Z"
      fill="#848484"
    />
  </svg>
)

export default LogoutSvg
