import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  grow: {
    height: "70px",
    width: "100%",
    background: "#464775",
  },
}));

export const TopNavBar = (props) => {
  const classes = useStyles();

  return <div className={classes.grow}></div>;
};
