import * as React from "react"

const DashboardSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18.179}
    height={18.179}
    {...props}
  >
    <path
      d="M1.818 0A1.831 1.831 0 0 0 0 1.818v6.363A1.831 1.831 0 0 0 1.818 10h4.545a1.831 1.831 0 0 0 1.818-1.818V1.818A1.831 1.831 0 0 0 6.363 0Zm10 0A1.831 1.831 0 0 0 10 1.818v2.727a1.831 1.831 0 0 0 1.818 1.818h4.545a1.831 1.831 0 0 0 1.818-1.818V1.818A1.831 1.831 0 0 0 16.361 0Zm-10 1.818h4.545v6.363H1.818Zm10 0h4.545v2.727h-4.546Zm0 6.363A1.831 1.831 0 0 0 10 10v6.363a1.831 1.831 0 0 0 1.818 1.818h4.545a1.831 1.831 0 0 0 1.818-1.818V10a1.831 1.831 0 0 0-1.818-1.818Zm0 1.818h4.545v6.363h-4.546Zm-10 1.818A1.831 1.831 0 0 0 0 13.634v2.727a1.831 1.831 0 0 0 1.818 1.818h4.545a1.831 1.831 0 0 0 1.818-1.818v-2.727a1.831 1.831 0 0 0-1.818-1.818Zm0 1.818h4.545v2.727H1.818Z"
      fill="#848484"
    />
  </svg>
)

export default DashboardSvg