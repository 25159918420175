import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Subheader } from "../../components/subHeader/SubHeader";
import { TextBox } from "../../components/textbox";
import { apiDocumentationStyles } from "./style";
import { DocumentationTable } from "./documentationTable";

export const ApiDocumentation = () => {
  const [tabindex, settabindex] = React.useState(0);

  const textBox = [
    "https://alertshub-api.crayond.com/api/v1/sendmessage",
    "Alert Key",
    "Push Receivers",
    "Push Title",
    "Push Body",
    "Push Date",
    "Push Click Action",
    "Push Icon",
    "To Mobiles",
    "SMS Body",
    "URL",
    "To Emails",
    "Email CC",
    "Email BCC",
    "From Mail",
    "Email Subject",
    "Email Body",
    "Email Attachments",
  ];
  const sampleTab = ["Sample Request", "Sample Response"];

  const classes = apiDocumentationStyles();

  const handleTab = (i) => {
    settabindex(i);
  };

  return (
    <div className={classes.root}>
      <Subheader title="API Documentation" />
      <Box className={classes.firstInput}>
        <TextBox
          label="API Calls"
          placeholder="https://alertshub-api.crayond.com/api/v1/sendmessage"
        />
      </Box>
      <Grid container className={classes.referenceParent}>
        <Grid item md={6} sm={12} className={classes.referenceParent1}>
          <Box className={classes.referenceDiv} m={1} ml={0} mt={2}>
            <Typography className={classes.textFieldhead}>
              Reference ID
            </Typography>
            <Box className={classes.referenceScroll} p={2}>
              {textBox?.map((e) => {
                return (
                  <Box className={classes.textBox}>
                    <TextBox label={false} placeholder={e} />
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Grid>
        <Grid item md={6} sm={12}>
          <Box className={classes.referenceDiv} m={1} mt={2} mr={0} >
            <Typography className={classes.reqHead}>Request Body</Typography>
            <Box className={classes.referenceScroll} p={2}>
              <Typography className={classes.dummy}>
                {`{ "reference_id": "", 
                                    "push_receivers": [],
                                    "push_title": [],
                                    "push_body": [],
                                    "push_data": {},
                                    "push_click_action": "", 
                                    "push_icon": "",
                                    "to_mobiles": [],
                                    "sms_body": [], 
                                    "URL": "",
                                    "to_emails": [],
                                        "email_CC": [],
                                        "email_BCC": [],
                                        "from_mail": "", 
                                        "email_subject": [],
                                        "email_body": [],
                                            "email_attachments": [ 
                                            { "content": "", 
                                            "filename": "", 
                                            "type": "",
                                                "disposition": "" } ] 
                 '}`}
              </Typography>
              <Box>
                <Typography className={classes.tryBtn}>Try</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box className={classes.referenceDiv} m={1} mb={2}>
        <DocumentationTable />
      </Box>
      <Box className={classes.referenceDiv} m={1} mb={2}>
        <Stack direction="row" className={classes.sampleTabBHead}>
          {sampleTab?.map((e, i) => {
            return (
              <Typography
                onClick={() => handleTab(i)}
                className={
                  i === tabindex
                    ? classes.sampleTabClickTxt
                    : classes.sampleTabTxt
                }
              >
                {e}
              </Typography>
            );
          })}
        </Stack>
        <Box className={classes.referenceDiv} p={2} mb={2}>
          <Typography className={classes.apicontent}>
            {`"reference_id": "test", "alert_key": "eyJhbGciOiJIUzI1NiIsInsfFR5cdsdsdCI6IkpXVCJ9sds.eyJpZCI6ImIyMTU2ZWNmLWFiNTgtNGY0Zi1iYjlsSFDADFjLWJjYTk0Yzhm...", "push_receivers": ["fDh9oeyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImIyMTU2ZWNmLWFiNTgtNGY0Zi1iYjljLWJjYTk0YzhmM2U3OCI..."], "push_title": ["Test","Title"], "push_body": ["Test","Body"], "push_data": {"test": "push data" }, "push_click_action": "https//www.example.com/path/", "push_icon": "https//www.example.com/logo.png", "to_mobiles": ["91XXXXXXXXXX"], "sms_body": ["Test","SMS","Body"], "URL":"http://alertshub.crayond.com", "to_emails": ["alertshub@crayond.com"], "email_CC": ["text_cc@alertshub.com","text_cc2@alertshub.com"], "email_BCC": ["text_bcc@alertshub.com","text_bcc2@alertshub.com"], "from_mail": "text_from_mail@alertshub.com", "email_subject": ["test","email","subject"], "email_body": ["test","email","body"], "email_attachments": [ {"content": 
                        "R0lGODlhPQBEAPeoAJosM//AwO/AwHVYZ/z595kzAP/s7P+goOXMv8+fhw/v739/f+8PD98fH/8mJl+fn/9ZWb8u7zPzWlwPzWlwvvWlwv...",
                         "filename": "test.jpeg", "type": "base64", "disposition": "attachment" } ] `}
          </Typography>
        </Box>
      </Box>
    </div>
  );
};
