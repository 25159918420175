import makeStyles from "@mui/styles/makeStyles";

export const AddRuleStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: "71px",
  },
  headerBox :{
    paddingLeft:"20px"
  },
  reportTable: {
    padding: "0px 32px 32px 32px",
    "& table": {
      borderRadius: "12px",
    },
  },
}));
