import React from "react";
import { makeStyles } from "@mui/styles";
import { TopNavBar, SideNavBar } from '../components';




const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  content: {
    width: '100%',
    height: '100%',
    backgroundColor: "#f8f8f8",

    [theme.breakpoints.up("sm")]: {
      paddingLeft: 240
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: "60px"
    }
  },
  topNavbar: {
    position: "fixed",
    top: "0",
    zIndex: "1",
    width: "100%"
  },
  sideNavbar: {
    [theme.breakpoints.down('md')]: {
      // display: "none"
    }
  }
}));


const withNavBars = (Component, params) => (props) => {
  const classes = useStyles({ props });

  return (
    <div className={classes.root}>
      {/* Your nav bars here */}
      <div className={classes.topNavbar}>
        <TopNavBar />
      </div>

      {<div className={classes.sideNavbar}>
        <SideNavBar />
      </div>}

      {/* Content */}
      <div className={classes.content}>
        <Component {...props}
          style={{
            padding: params?.master ? "70px 0px 10px 0px" : params?.boxShadow === false ? "70px 0px 0px 0px" : "70px 10px 10px 10px",
          }}>{props.children}</Component>
      </div>
    </div>
  );
};

export default withNavBars;
