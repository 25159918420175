import { Box, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import SearchIMG from "../../src/assets/svgIcons/searchIcon";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  search: {
    position: "relative",
    border: "1px solid transparent",
    borderRadius: theme.palette.borderRadius,
    marginLeft: 0,
    width: "100%",
    display: "inline-flex",
    backgroundColor: "#FFFFFF",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    height: "40px",
    color: "#C5C5C5",
    width: "100%",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",

    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  textfield: {
    "& .MuiOutlinedInput-root": {
      borderRadius: theme.palette.borderRadius,

      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  },
  Label: {
    color: theme.palette.tertiary.light,
    fontFamily: "tenant_semibold",
    fontSize: "14px",
    marginBottom: "5px",
  },
}));

export const SearchFilter = ({
  handleChange = () => false,
  value = "",
  placeholder = "",
  color = false,
  isReadonly = false,
  label = "",
}) => {
  const classes = useStyles(color);

  const getLabel = (label = "") => {
    return (
      <Typography className={classes.Label} noWrap>
        {label}
      </Typography>
    );
  };
  return (
    <div className={classes.root}>
      <Box>
        {getLabel(label)}
        <div className={classes.search}>
          <TextField
            autoComplete="off"
            InputProps={{
              startAdornment: (
                <SearchIMG
                  color="#a4b2c1"
                  style={{ margin: 8, marginLeft: "3px", color: "#999999" }}
                />
              ),
            }}
            placeholder={placeholder ? placeholder : "Search"}
            onChange={(e) => handleChange(e.target.value)}
            value={value}
            disabled={isReadonly}
            size={"small"}
            fullWidth
            className={classes.textfield}
          />
        </div>
      </Box>
    </div>
  );
};
