import * as React from "react"

const SettingSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Group 93692"
    width={26}
    height={26}
    {...props}
  >
    <path
      data-name="icons8-settings (2)"
      d="M11.83 4.23a1.1 1.1 0 0 0-1.08.91l-.232 1.348a7.215 7.215 0 0 0-.362.148l-1.115-.788a1.1 1.1 0 0 0-1.408.121L5.969 7.63a1.1 1.1 0 0 0-.12 1.408l.787 1.116c-.053.118-.1.239-.148.361l-1.348.232a1.1 1.1 0 0 0-.91 1.083v2.348a1.1 1.1 0 0 0 .91 1.08l1.348.232c.047.122.095.244.148.362l-.788 1.115a1.1 1.1 0 0 0 .121 1.408L7.63 20.03a1.1 1.1 0 0 0 1.408.12l1.116-.787c.118.053.239.1.361.148l.232 1.348a1.1 1.1 0 0 0 1.081.91h2.348a1.1 1.1 0 0 0 1.08-.91l.232-1.348c.122-.047.244-.095.362-.148l1.115.788a1.1 1.1 0 0 0 1.405-.121l1.661-1.66a1.1 1.1 0 0 0 .12-1.408l-.787-1.116c.053-.118.1-.239.148-.361l1.348-.232a1.1 1.1 0 0 0 .91-1.081V11.83a1.1 1.1 0 0 0-.91-1.08l-1.348-.232a7.215 7.215 0 0 0-.148-.362l.788-1.115a1.1 1.1 0 0 0-.122-1.411l-1.66-1.661a1.092 1.092 0 0 0-1.408-.12l-1.116.787c-.118-.053-.239-.1-.361-.148l-.232-1.348a1.1 1.1 0 0 0-1.079-.91Zm.462 1.594h1.424a.05.05 0 0 1 .049.041l.307 1.788a19.587 19.587 0 0 1 1.957.809l1.48-1.046a.051.051 0 0 1 .065.005l1.002 1.009a.05.05 0 0 1 .006.064l-1.046 1.481a20.335 20.335 0 0 1 .809 1.956l1.788.308a.049.049 0 0 1 .041.049v1.424a.05.05 0 0 1-.042.049l-1.788.307a19.34 19.34 0 0 1-.809 1.957l1.046 1.481a.05.05 0 0 1-.006.064l-1.005 1.007a.051.051 0 0 1-.065.005l-1.475-1.045q-.981.461-1.957.809l-.313 1.784a.049.049 0 0 1-.049.041h-1.424a.05.05 0 0 1-.049-.042l-.308-1.784a18.29 18.29 0 0 1-1.957-.809l-1.481 1.046a.05.05 0 0 1-.064-.006L7.422 17.57a.051.051 0 0 1-.005-.065l1.046-1.48q-.466-1-.809-1.957l-1.788-.308a.049.049 0 0 1-.041-.049v-1.424a.05.05 0 0 1 .041-.049l1.788-.308a20.136 20.136 0 0 1 .809-1.956L7.417 8.493a.05.05 0 0 1 .006-.064L8.43 7.422a.051.051 0 0 1 .065-.005l1.48 1.046a20.319 20.319 0 0 1 1.957-.809l.308-1.788a.049.049 0 0 1 .048-.042Zm.712 3.588A3.588 3.588 0 1 0 16.592 13 3.587 3.587 0 0 0 13 9.412Zm0 1.594a1.993 1.993 0 1 1-1.993 1.993A1.993 1.993 0 0 1 13 11.007Z"
      fill="#848484"
    />
  </svg>
)

export default SettingSvg
