export const AppRoutes = {
  smallChartCard: "/smallChartCard",
  loginScreen: "/",
  totalVendors: "/totalVendors",
  apiDocumentation: "/apiDocumentation",
  alertConfig: "/alertConfig",
  totalReports: "/totalReports",
  settingsScreen: "/settingsScreen",
  totalUsers: "/totalUsers",
  addRule: "/addRule",
};
