import React from "react";
import { Paper, Stack, Typography } from "@mui/material";
import Logo1 from "../../../assets/favico.png";
import { Box } from "@mui/system";
import DashboardSvg from "../../../assets/svgIcons/dashboard";
import ReportsSvg from "../../../assets/svgIcons/reports";
import UserManagementSvg from "../../../assets/svgIcons/userManagement";
import SettingSvg from "../../../assets/svgIcons/settings";
import DocumentationSvg from "../../../assets/svgIcons/documentation";
import ConfigSvg from "../../../assets/svgIcons/config";
import AlertTemplateSvg from "../../../assets/svgIcons/alertTemplate";
import AssetSvg from "../../../assets/svgIcons/assets";
import LogoutSvg from "../../../assets/svgIcons/logout";
import { useStyles } from "./style";
import { useNavigate } from "react-router-dom";
import AddRule from "../../../assets/svgIcons/adddrule";
import { AppRoutes } from "../../../router/routes";

export const MenuItems = (props) => {
  const { Close, setClose, matches } = props;
  const [route, setRoute] = React.useState(0);

  const navigate = useNavigate();

  const handleClick = () => {
    if (matches) {
      setClose(!Close);
    } else {
      Close && setClose(!Close);
    }
  };
  // navigate navlinks fn
  const handleNavigate = (v, i) => {
    setRoute(i);
    navigate(v);
  };

  const classes = useStyles(props);

  const navItems = [
    {
      name: "Dash Board",
      svg: <DashboardSvg />,
      route: AppRoutes.totalVendors

    },
    {
      name: "Reports",
      svg: <ReportsSvg />,
      route: AppRoutes.totalReports

    },
    {
      name: "Settings",
      svg: <SettingSvg />,
      route: AppRoutes.settingsScreen

    },
    {
      name: "User Management",
      svg: <UserManagementSvg />,
      route: AppRoutes.totalUsers

    },
    {
      name: "API Documentation",
      svg: <DocumentationSvg />,
      route: AppRoutes.apiDocumentation

    },
    {
      name: "Alert Configurations",
      svg: <ConfigSvg />,
      route: AppRoutes.alertConfig

    },
    {
      name: "Alert Templates",
      svg: <AlertTemplateSvg />,
      route: "",
    },
    {
      name: "Alert Rule",
      svg: <AddRule />,
      route: AppRoutes.addRule,
    },
    {
      name: "Assets",
      svg: <AssetSvg />,
      route: "",
    },
    {
      name: "Log out",
      svg: <LogoutSvg />,
      route: "/",
    },
  ];

  return (
    <Paper className={Close ? classes.drawer1 : classes.drawer} square>
      <div className={classes.drawerContainer}>
        <Box>
          <Box
            className={Close ? classes.logo2 : classes.logo1}
            onClick={handleClick}
          >
            <img src={Logo1} alt="logo" style={{ width: "100%" }}></img>
          </Box>
          {navItems?.map((e, i) => {
            return (
              <>
                <Box>
                  <Box className={classes.Box}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      p={1}
                      onClick={() => handleNavigate(e?.route, i)}
                      className={
                        i === route ? classes.navItemClick : classes.navItem
                      }
                    >
                      <Box className={classes.navitems}>{e?.svg}</Box>
                      {!Close && (
                        <Typography ml={2} className={classes.navitems}>
                          {e?.name}
                        </Typography>
                      )}
                    </Stack>
                  </Box>
                </Box>
              </>
            );
          })}
        </Box>
      </div>
    </Paper>
  );
};
