import { Box, Grid } from "@mui/material";
import React from "react";
import { Subheader } from "../../components/subHeader/SubHeader";
import { TableWithPagination } from "../../components/tableWithPagination";
import { config } from "../../config";
import { NetworkCall } from "../../networkCall";
import { NetWorkCallMethods } from "../../utils";
import { TotalUsersStyles } from "./style";

export const TotalUsers = () => {
  const classes = TotalUsersStyles();
  const [userRow, setUserRow] = React.useState([]);

  // Table - 3

  const Tablepath_3 = [
    "userName",
    "userId",
    "role",
    "emailId",
    "contactNumber",
    "status",
  ];

  const Tableheading_3 = [
    { title: "User Name", field: "userName" },
    { title: "User ID", field: "userId" },
    { title: "Role", field: "role" },
    { title: "Email ID", field: "emailId" },
    { title: "Contact Number", field: "contactNumber" },
    { title: "Status", field: "status" },
  ];

  function createData_3(
    userName,
    userId,
    role,
    emailId,
    contactNumber,
    status
  ) {
    return {
      userName,
      userId,
      role,
      emailId,
      contactNumber,
      status,
    };
  }

  React.useEffect(() => {
    getTableData();
  }, []);
  const getTableData = () => {
    NetworkCall(
      `${config.api_url}/users/get`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    )
      .then((response) => {
        let res = response?.data;
        console.log("res",res)
        const tempRow = {
          userName: res?.firstName + " " + res?.lastName,
          userId: res?.id,
          role: res?.role ?? "-",
          emailId: res?.email,
          contactNumber: res?.mobile,
          status: res?.status ?? "-",
        };
        setUserRow([...userRow,tempRow]);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <div>
      <Grid container className={classes.marginTop_2}>
        <Grid item xs={12}>
          <Box className={classes.headerBox}>
            <Subheader
              title="Total Users (12)"
              filterIcon
              searchFilter
              addIcon
            />
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.userTable}>
          <TableWithPagination
            heading={Tableheading_3}
            rows={userRow}
            path={Tablepath_3}
            showpagination
            dataType={[
              { type: ["text"], name: "userName" },
              { type: ["text"], name: "userId" },
              { type: ["text"], name: "role" },
              { type: ["text"], name: "emailId" },
              { type: ["text"], name: "contactNumber" },
              { type: ["isActive"], name: "status" },
            ]}
          />
        </Grid>
      </Grid>
    </div>
  );
};
