import * as React from "react"

const ConfigSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Group 93692"
    width={26}
    height={26}
    {...props}
  >
    <path
      d="M6.343 4A2.346 2.346 0 0 0 4 6.343v10.225a2.346 2.346 0 0 0 2.343 2.34h2.59a1.062 1.062 0 0 1 .832.4l1.566 1.959a2.343 2.343 0 0 0 3.658 0l1.561-1.957a1.063 1.063 0 0 1 .832-.4h2.589a2.346 2.346 0 0 0 2.343-2.343V6.343A2.346 2.346 0 0 0 19.976 4Zm0 1.278h13.633a1.067 1.067 0 0 1 1.065 1.065v10.225a1.067 1.067 0 0 1-1.065 1.065h-2.589a2.336 2.336 0 0 0-1.831.879l-1.564 1.96a1.1 1.1 0 0 1-1.664 0l-1.566-1.96a2.332 2.332 0 0 0-1.829-.879h-2.59a1.067 1.067 0 0 1-1.065-1.065V6.343a1.067 1.067 0 0 1 1.065-1.065Zm6.835 2.13a.639.639 0 0 0-.657.639v4.26a.639.639 0 1 0 1.278 0V8.05a.639.639 0 0 0-.621-.639Zm-.018 7.029a1.065 1.065 0 1 0 1.065 1.065 1.065 1.065 0 0 0-1.065-1.064Z"
      fill="#848484"
    />
  </svg>
)

export default ConfigSvg
