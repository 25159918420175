import React from "react";
import withNavBars from "../../HOCs/withNavBars";
import { SettingsScreen } from "./settingsScreen";

class SettingsScreenParent extends React.Component {
  render() {
    return <SettingsScreen />;
  }
}

export default withNavBars(SettingsScreenParent);
