import React from "react";
import { withNavBars } from "../../HOCs";
import { ApiDocumentation } from "./apiDocumentation";

class ApiDocumentationParent extends React.Component {
  render() {
    return (
      <div>
        <ApiDocumentation />
      </div>
    );
  }
}

export default withNavBars(ApiDocumentationParent);
