import makeStyles from "@mui/styles/makeStyles";

export const apiDocumentationStyles = makeStyles((theme) => ({
  root: {
    margin: "16px",
    [theme.breakpoints.up("lg")]: {
      padding: "6% 0 1% 0 ",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "8% 0 1% 0 ",

    },
    [theme.breakpoints.down("md")]: {
      padding: "9% 0 1% 0 ",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "18% 0 1% 0 !important",
    },
  },
  firstInput: {
    backgroundColor: "#fff",
    borderRadius: theme.palette.borderRadius,
    boxShadow: theme.palette.primary.boxShadow,
    padding: "24px 32px",
    [theme.breakpoints.down("sm")]: {
      padding: "16px 24px",
    },
  },
  textBox: {
    padding: "8px 0",
  },
  textFieldhead: {
    color: theme.typography.color.darkGrey,
    fontSize: "15px",
    padding: " 16px 16px 0px 16px",
  },
  referenceDiv: {
    backgroundColor: theme.typography.color.white,
    boxShadow: theme.palette.primary.boxShadow,
    borderRadius: theme.palette.borderRadius,
  },
  referenceScroll: {
    height: "600px",
    overflow: "scroll",
    padding: "16px",
    [theme.breakpoints.down("lg")]: {
      height: "700px",
    },
    [theme.breakpoints.down("md")]: {
      height: "800px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      padding: "12px",
    },
  },
  reqHead: {
    color: theme.typography.color.black,
    fontSize: "16px",
    fontFamily: "crayond_medium",
    padding: " 16px 16px 0px 16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      paddingBottom: "6px"
    },
  },
  tryBtn: {
    fontSize: "14px",
    backgroundColor: theme.typography.color.blue,
    borderRadius: theme.palette.borderRadius,
    width: "65px",
    height: "45px",
    color: theme.palette.primary.contrastText,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "auto",
  },
  sampleTabBHead: {
    backgroundColor: theme.typography.color.voilet,
    borderTopLeftRadius: theme.palette.borderRadius,
    borderTopRightRadius: theme.palette.borderRadius,
  },
  sampleTabTxt: {
    cursor: "pointer",
    fontSize: "16px",
    fontFamily: "crayond_medium",
    marginLeft: "32px",
    paddingTop: "16px",
    paddingBottom: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
      marginLeft: "20px",
      paddingTop: "12px",
      paddingBottom: "12px",
    },
  },
  sampleTabClickTxt: {
    cursor: "pointer",
    fontSize: "16px",
    fontFamily: "crayond_medium",
    color: theme.typography.color.blue,
    marginLeft: "32px",
    paddingTop: "16px",
    paddingBottom: "16px",
    borderBottom: `3px solid ${theme.typography.color.blue}`,
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
      marginLeft: "20px",
      paddingTop: "12px",
      paddingBottom: "12px",
    },
  },
  dummy: {
    height: "100%",
    whiteSpace: "break-spaces",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },

  },
  apicontent: {
    display: "block",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },

  },
  referenceParent: {
    flexWrap: "nowrap",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  referenceParent1: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
  }
}));
