import * as React from "react"

const AssetSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Group 93692"
    width={26}
    height={26}
    {...props}
  >
    <path
      d="M7.01 4A2.013 2.013 0 0 0 5 6.01v13.85a2.013 2.013 0 0 0 2.01 2.01h10.053v-1.34H7.01a.671.671 0 0 1-.67-.67V6.01a.671.671 0 0 1 .67-.67h5.138v3.8a2.013 2.013 0 0 0 2.01 2.01h3.8v2.837a1.516 1.516 0 0 1 1.34 0v-3.509A.668.668 0 0 0 19.1 10l-5.808-5.8a.667.667 0 0 0-.474-.2Zm6.478 2.288 3.52 3.52h-2.85a.671.671 0 0 1-.67-.67Zm5.138 8.435a.668.668 0 0 0-.474.2l-2.234 2.234a.67.67 0 0 0 .948.948l1.09-1.09v5.084a.67.67 0 0 0 1.34 0v-5.088l1.09 1.09a.67.67 0 0 0 .948-.948L19.1 14.919a.667.667 0 0 0-.473-.196Z"
      fill="#848484"
    />
  </svg>
)

export default AssetSvg