/* eslint-disable react/jsx-no-target-blank */
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
  Avatar,
  Button,
  Checkbox,
  IconButton,
  MenuItem,
  Popover,
  TableRow,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import moment from "moment-timezone";
import * as React from "react";
import ReactQuill from "react-quill";
import CopyIcon from "../../assets/copyIcon";
import EditIcon from "@mui/icons-material/Edit";
import EditIMG from "../../assets/edit";
import InfoIcon from "../../assets/infoIcon";
import LockKey from "../../assets/lockKey";
import { enumName } from "../../utils";
import { TableStyles } from "./style";

const StyledTableCell = styled(TableCell)(({ theme, headingColor }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.typography.color.secondary,
    fontSize: "14px",
    border: 0,
    backgroundColor: headingColor ?? "#F2F4F7",
    padding: "16px",
    zIndex: "0 !important",
  },

  [`&.${tableCellClasses.body}`]: {
    color: "#848484",
    fontSize: "14px",
    // border: 0,
    whiteSpace: "normal",
    wordWrap: "break-word",
    backgroundColor: headingColor ?? "auto",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#FFFFFF",
  },

  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const TableWithPagination = (props) => {
  const classes = TableStyles(props);
  //   const totalCount = props?.totalRowsCount > -1 ? props?.totalRowsCount : "";
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [index, setIndex] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const modules = {
    toolbar: false,
  };
  const getComponentType = (val, data) => {
    const main = data.main;

    switch (data.type) {
      case "status": {
        if (data.value === "Approved") {
          return <p style={{ color: "#267C24", margin: "0" }}>{data.value}</p>;
        } else if (data.value === "Rejected") {
          return <p style={{ color: "red", margin: "0" }}>{data.value}</p>;
        } else if (data.value === "Pending") {
          return <p style={{ color: "#78B1FE", margin: "0" }}>{data.value}</p>;
          // } else if (data.value === "Lost Lead") {
          //   return <p style={{ color: "red", margin: '0' }}>{data.value}</p>;
        } else if (data.value === "Lost Lead") {
          return <p className={classes.red}>{data.value}</p>;
        } else if (data.value === "In Progress") {
          return (
            <Typography className={classes.green} width="max-content">
              {data.value}
            </Typography>
          );
        } else if (data.value === "Converted") {
          return <p className={classes.lightBlue}>{data.value}</p>;
        } else if (data.value === "Cancelled") {
          return <p style={{ color: "#98A0AC", margin: "0" }}>{data.value}</p>;
        } else if (data.value === "Yet To Activate") {
          return <p style={{ color: "#FF9340", margin: "0" }}>{data.value}</p>;
        } else if (data.value === "Active") {
          return <p style={{ color: "#11AA00", margin: "0" }}>{data.value}</p>;
        } else if (data.value === "InActive") {
          return <p style={{ color: "#FF4B4B", margin: "0" }}>{data.value}</p>;
        } else if (data.value === "Quote Accepted") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#5AC782",
                fontSize: "10px",
                textAlign: "center",
                borderRadius: "4px",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data?.value === "open") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#5AC782",
                fontSize: "10px",
                textAlign: "center",
                borderRadius: "4px",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "close" || data?.value === "closed") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#CED3DD",
                fontSize: "10px",
                textAlign: "center",
                borderRadius: "4px",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data?.value === "Closed") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "red",
                fontSize: "10px",
                textAlign: "center",
                borderRadius: "4px",
                padding: "4px",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data?.value === "Open") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#5AC782",
                fontSize: "10px",
                textAlign: "center",
                borderRadius: "4px",
                padding: "4px",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "requested for visit") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#FF9340",
                fontSize: "10px",
                textAlign: "center",
                borderRadius: "4px",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "requested for re-quote accepted") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#FF9340",
                fontSize: "10px",
                textAlign: "center",
                borderRadius: "4px",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "inProgress") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#78B1FE",
                fontSize: "14px",
                textAlign: "center",
                borderRadius: "4px",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "In Progress") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#78B1FE",
                fontSize: "14px",
                textAlign: "center",
                borderRadius: "4px",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "Active") {
          return (
            <Typography className={classes.green}>{data.value}</Typography>
          );
        } else if (data.value === "Paid") {
          return (
            <Typography className={classes.active}>{data.value}</Typography>
          );
        } else if (data.value === "Vacant") {
          return <span className={classes.green}>{data.value}</span>;
        } else if (data.value === "Occupied") {
          return <span className={classes.red}>{data.value}</span>;
        } else if (data.value === "Inactive") {
          return <Typography className={classes.red}>{data.value}</Typography>;
        } else if (data.value === "Unpaid") {
          return <Typography className={classes.red}>{data.value}</Typography>;
        } else if (data.value === "delete") {
          return (
            <div>
              {props?.delete && (
                <IconButton onClick={() => props.handleIcon("delete", main)}>
                  <DeleteForeverIcon color="primary" />
                </IconButton>
              )}
            </div>
          );
        } else if (data.value === true) {
          return <Typography className={classes.active}>Active</Typography>;
        } else if (data.value === false) {
          return <Typography className={classes.inactive}>Inactive</Typography>;
        } else {
          return data.value;
        }
      }
      case "status2": {
        if (data?.value === "Closed") {
          return (
            <Typography
              sx={{
                color: "#000",
                backgroundColor: "#F2F4F7",
                fontSize: "10px",
                textAlign: "center",
                borderRadius: "4px",
                padding: "4px",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data?.value === "Open") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#5AC782",
                fontSize: "10px",
                textAlign: "center",
                borderRadius: "4px",
                padding: "4px",
              }}
            >
              {data.value}
            </Typography>
          );
        } else {
          return data.value;
        }
      }
      case "maintenanceRequestStatus": {
        if (data?.value === "Closed") {
          return (
            <Typography className={classes.green}>{data.value}</Typography>
          );
        } else if (data?.value === "Open") {
          return <Typography className={classes.red}>{data.value}</Typography>;
        } else {
          return data.value;
        }
      }
      case "object_status": {
        if (data?.value.value === enumName.approved) {
          return (
            <Typography
              sx={{
                color: "#5AC782",
                fontSize: "14px",
              }}
            >
              {data.value.label}
            </Typography>
          );
        } else if (data?.value.value === enumName.cancelled) {
          return (
            <Typography
              sx={{
                color: "#4E5A6B",
                fontSize: "14px",
              }}
            >
              {data.value.label}
            </Typography>
          );
        } else if (data?.value.value === enumName.pending) {
          return (
            <Typography
              sx={{
                color: "#FF9340",
                fontSize: "14px",
              }}
            >
              {data.value.label}
            </Typography>
          );
        } else if (data?.value.value === enumName.rejected) {
          return (
            <Typography
              sx={{
                color: "#FF4B4B",
                fontSize: "14px",
              }}
            >
              {data.value.label}
            </Typography>
          );
        } else {
          return data.value;
        }
      }
      case "icon": {
        let editviewtoggle = (
          <div style={{ display: "flex" }}>
            {props?.edit && (
              <IconButton onClick={() => props.handleIcon("edit", main)}>
                <EditIMG color="#98a0ac" />
              </IconButton>
            )}
            {props?.view && (
              <IconButton onClick={() => props.handleIcon("view", main)}>
                <RemoveRedEyeOutlinedIcon
                  sx={{ fontSize: "16px", color: "#98A0AC" }}
                />
              </IconButton>
            )}

            {props?.edit && (
              <Switch
                checked={data.is_active}
                onChange={(e) =>
                  props.handleIcon("toggle", main, e.target.checked)
                }
              />
            )}
          </div>
        );
        let editview = (
          <div style={{ display: "flex" }}>
            {props?.edit && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  props.handleIcon("edit", main);
                }}
              >
                <EditIMG color="#98a0ac" />
              </IconButton>
            )}
            {props?.view && (
              <IconButton onClick={() => props.handleIcon("view", main)}>
                <RemoveRedEyeOutlinedIcon
                  sx={{ fontSize: "16px", color: "#98A0AC" }}
                />
              </IconButton>
            )}
          </div>
        );
        let deleteIcon = (
          <div style={{ display: "flex" }}>
            {props?.delete && (
              <IconButton onClick={() => props.handleIcon("delete", main)}>
                <DeleteOutlineIcon
                  sx={{ fontSize: "16px", color: "#98A0AC" }}
                />
              </IconButton>
            )}
          </div>
        );
        let viewdelete = (
          <div style={{ display: "flex" }}>
            {props?.edit && (
              <IconButton onClick={() => props.handleIcon("edit", main)}>
                <EditIMG color="#98a0ac" />
              </IconButton>
            )}
            {props?.delete && (
              <IconButton onClick={() => props.handleIcon("delete", main)}>
                <DeleteOutlineIcon
                  sx={{ fontSize: "16px", color: "#98A0AC" }}
                />
              </IconButton>
            )}
          </div>
        );
        let edittoggle = (
          <div style={{ display: "flex" }}>
            {props?.edit && (
              <IconButton
                onClick={() => props.handleIcon("edit", main, props?.edit)}
              >
                <EditIMG color="#98a0ac" />
              </IconButton>
            )}
            {props?.edit && (
              <Switch
                checked={main.is_active}
                onChange={(e) => props.handleIcon("toggle", main, props?.edit)}
              />
            )}
          </div>
        );
        let viewedittoggle = (
          <div style={{ display: "flex" }}>
            {props?.edit && (
              <IconButton
                onClick={() => props.handleIcon("edit", main, props?.edit)}
              >
                <EditIMG color="#98a0ac" />
              </IconButton>
            )}
            {props?.view && (
              <IconButton
                onClick={() => props.handleIcon("view", main, props?.view)}
              >
                <RemoveRedEyeOutlinedIcon
                  sx={{ fontSize: "16px", color: "#98A0AC" }}
                />
              </IconButton>
            )}
            {props?.edit && (
              <Switch
                checked={main.is_active}
                onChange={(e) => props.handleIcon("toggle", main, props?.edit)}
              />
            )}
          </div>
        );

        if (data.icon === "editviewtoggle") {
          return editviewtoggle;
        }
        if (data.icon === "deleteIcon") {
          return deleteIcon;
        }
        if (data.icon === "viewedittoggle") {
          return viewedittoggle;
        }
        if (data.icon === "edittoggle") {
          return edittoggle;
        }
        if (data.icon === "viewdelete") {
          return viewdelete;
        }
        if (data.icon === "editview") {
          return editview;
        } else if (data.icon === "edit") {
          return (
            <div>
              {props?.edit && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    props.handleIcon("edit", main, props?.edit);
                  }}
                >
                  <EditIMG color="#98a0ac" />
                </IconButton>
              )}
            </div>
          );
        } else if (data.icon === "view") {
          return (
            <div>
              {props?.view && (
                <IconButton onClick={() => props.handleIcon("view", main)}>
                  <RemoveRedEyeOutlinedIcon
                    sx={{ fontSize: "16px", color: "#98A0AC" }}
                  />
                </IconButton>
              )}
            </div>
          );
        } else if (data.icon === "arrow") {
          return (
            <div style={{ display: "flex" }}>
              <IconButton size="small" className={classes.arrow}>
                <ArrowForwardIosIcon
                  size="small"
                  style={{ fontSize: "16px" }}
                />
              </IconButton>
            </div>
          );
        }

        break;
      }
      case "leadEdit": {
        return (
          <>
            {props?.view && (
              <IconButton onClick={() => props.handleIcon("view", main)}>
                <RemoveRedEyeOutlinedIcon
                  sx={{ fontSize: "16px", color: "#98A0AC" }}
                />
              </IconButton>
            )}
          </>
        );
      }
      case "propertyType": {
        return (
          <>
            {data.value && (
              <Typography
                sx={{
                  color: "white",
                  backgroundColor: "#78B1FE",
                  fontSize: "14px",
                  textAlign: "center",
                  borderRadius: "4px",
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;&nbsp;{data.value}&nbsp;&nbsp;&nbsp;
              </Typography>
            )}
          </>
        );
      }
      case "blockType": {
        return (
          <>
            {data.value && (
              <Typography
                sx={{
                  color: "white",
                  backgroundColor: "#78B1FE",
                  fontSize: "14px",
                  textAlign: "center",
                  borderRadius: "4px",
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;&nbsp;{data.value}&nbsp;&nbsp;&nbsp;
              </Typography>
            )}
          </>
        );
      }
      case "checkBox": {
        return (
          <div>
            {props?.edit && (
              <Checkbox
                sx={{ padding: "0px" }}
                onChange={(e) =>
                  props.handleIcon("checkBox", main, e?.target?.checked)
                }
                color="success"
                checked={data.value}
                checkedIcon={<CheckCircleIcon />}
                icon={<CircleOutlinedIcon style={{ color: "#c1c1c1" }} />}
              />
            )}
          </div>
        );
      }
      case "tooltip": {
        return (
          <>
            <Button
              aria-describedby={props?.id}
              className={classes.tooltip}
              onClick={(e) => props?.handleHover(e, main)}
              // onMouseLeave={props?.handleClose}
            >
              {data.value}
            </Button>
            <Popover
              className={classes.menu}
              style={{ boxShadow: "none !important" }}
              id={props?.id}
              open={props?.open}
              anchorEl={props?.anchorEl}
              onClose={props?.handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {props?.roles?.map((val) => {
                return <Typography sx={{ p: 1 }}>{val?.name}</Typography>;
              })}
            </Popover>
          </>
        );
      }
      case "avatar": {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={data.image} sx={{ width: 34, height: 34 }} />
            <Typography style={{ marginLeft: 10 }}>{data.value}</Typography>
          </div>
        );
      }
      case "avatarText": {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              src={data.value.image}
              sx={{ width: 34, height: 34, lineHeight: 0, fontSize: 14 }}
            >
              {data?.value?.name ? data?.value?.name?.charAt(0) : ""}
            </Avatar>
            <Typography style={{ marginLeft: 10 }}>
              {data.value.name}
            </Typography>
          </div>
        );
      }
      case "avatarmanagement": {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={data.value} sx={{ width: 34, height: 34 }} />
          </div>
        );
      }
      case "text": {
        return (
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
            className={classes.text}
          >
            {data.value ?? "-"}
          </Typography>
        );
      }
      case "textObject": {
        return (
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
            className={classes.text}
          >
            {data?.value?.label ?? "-"}
          </Typography>
        );
      }
      case "textLink": {
        return (
          <div
            onClick={() => props?.onUnitClick(main, data)}
            style={{ textDecorationLine: "underline", color: "blue" }}
          >
            {data.value}
          </div>
        );
      }
      case "increment": {
        return data.index + 1;
      }
      case "image": {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <img src={data.image} alt={data.name}></img>
            <Typography style={{ marginLeft: 10 }}>{data.value}</Typography>
            {/* <Typography className={classes.change}>Change</Typography> */}
          </div>
        );
      }
      case "PFImage": {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              src={data.value !== "" ? data.value : "/images/pf.svg"}
              sx={{ width: 34, height: 34 }}
            />
          </div>
        );
      }
      case "logo": {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              src={data?.value?.logo}
              sx={{ width: 34, height: 34, lineHeight: 0, fontSize: 14 }}
            >
              {data?.value?.name ? data?.value?.name?.charAt(0) : ""}
            </Avatar>
          </div>
        );
      }
      case "link": {
        return (
          <Typography
            className={classes.link}
            onClick={() => props?.handleLink(data)}
          >
            {main.url}
          </Typography>
        );
      }
      case "date": {
        return (
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
            className={classes.text}
          >
            {data.value
              ? moment(data.value).tz(moment.tz.guess()).format("DD MMM YYYY")
              : "-"}
          </Typography>
        );
      }
      case "description": {
        return (
          <div>
            <Typography
              noWrap
              readOnly
              className={classes.text}
              style={{
                wordBreak: "break-all",
                overflowWrap: "anywhere",
              }}
            >
              {data?.value?.length > 0
                ? `${data?.value?.substring(0, 15)}${
                    data?.value?.length > 15 ? "..." : ""
                  } `
                : "-"}
            </Typography>
          </div>
        );
      }
      case "quill": {
        return (
          <div>
            <ReactQuill
              noWrap
              readOnly
              theme="bubble"
              // value={data?.value ?? ""}
              value={
                data?.value?.length > 0
                  ? `${data?.value?.substring(0, 100)}${
                      data?.value?.length > 10 ? "..." : ""
                    } `
                  : "-"
              }
              modules={modules}
              className={classes.text}
              style={{
                // wordBreak: "break-all",
                overflowWrap: "anywhere",
                width: "max-content",
              }}
            />
          </div>
        );
      }
      case "redirect": {
        return (
          <a target="_blank" href={data.value}>
            {data.value}
          </a>
        );
      }
      case "object": {
        return data.value[data?.objectOption] ?? "-";
      }
      case "phone": {
        return (
          <>
            {main.code} {main.mobile}
          </>
        );
      }
      case "more": {
        return (
          <div
            style={{ display: props?.edit || props?.delete ? "flex" : "none" }}
          >
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {props?.edit && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("edit", main);
                    }}
                    className={classes.menuItem}
                  >
                    Edit
                  </MenuItem>
                )}
                {props?.edit && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("active", main, main.status);
                    }}
                    className={classes.menuItem}
                  >
                    {main.status === "Active" || main.is_active === true
                      ? "In-active"
                      : "Active"}
                  </MenuItem>
                )}

                {props?.delete && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("delete", main, main.delete);
                    }}
                    className={classes.menuItem}
                  >
                    Delete
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "more_2": {
        return (
          <div
            style={{ display: props?.edit || props?.delete ? "flex" : "none" }}
          >
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {props?.edit && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("edit", main);
                    }}
                    className={classes.menuItem}
                  >
                    Edit
                  </MenuItem>
                )}
                {props?.delete && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("delete", main);
                    }}
                    className={classes.menuItem}
                  >
                    Delete
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "more_3": {
        return (
          <div
            style={{ display: props?.edit || props?.delete ? "flex" : "none" }}
          >
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {props?.edit && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("active", main, main.status);
                    }}
                    className={classes.menuItem}
                  >
                    {main.status === "Active" ? "In-active" : "Active"}
                  </MenuItem>
                )}

                {props?.delete && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("delete", main, main.delete);
                    }}
                    className={classes.menuItem}
                  >
                    Delete
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "more_4": {
        return (
          <div style={{ display: props?.edit ? "flex" : "none" }}>
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>
            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {props?.edit && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("edit", main);
                    }}
                    className={classes.menuItem}
                  >
                    Edit
                  </MenuItem>
                )}
                {props?.edit && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("active", main, main.status);
                    }}
                    className={classes.menuItem}
                  >
                    {main.status === "Active" ? "In-active" : "Active"}
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "more_5": {
        return (
          <div style={{ display: props?.edit ? "flex" : "none" }}>
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {" "}
                {props?.edit && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("edit", main);
                    }}
                    className={classes.menuItem}
                  >
                    Edit
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "view_more": {
        return (
          <div>
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    props.handleIcon("active", main, main.status);
                  }}
                  className={classes.menuItem}
                >
                  {main.is_active ? "In-active" : "Active"}
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    handleClose();
                    props.handleIcon("view", main, main.delete);
                  }}
                  className={classes.menuItem}
                >
                  View
                </MenuItem>
              </Menu>
            )}
          </div>
        );
      }
      case "error_icon": {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              transform: "rotate(180deg)",
            }}
          >
            {main.iconToolTip && (
              <InfoIcon
                fill="#FF4B4B"
                hoverFill="#FF4B4B"
                info={main.iconToolTip}
              />
            )}
          </div>
          // {main?.iconToolTip && <LightTooltip title={main.iconToolTip} placement="top">
          //    {/* <ErrorOutlineIcon /> */}
          //    <InfoIcon fill="#FF4B4B" />
          //  </LightTooltip>}
        );
      }
      case "block": {
        return (
          <div
            onClick={() => props?.onUnitClick(main, "block")}
            style={{ textDecorationLine: "underline", color: "blue" }}
          >
            {data.value}
          </div>
        );
      }
      case "unit": {
        return (
          <div
            onClick={() => props?.onUnitClick(main, "unit")}
            style={{ textDecorationLine: "underline", color: "blue" }}
          >
            {data.value}
          </div>
        );
      }
      case "floor": {
        return (
          <div
            onClick={() => props?.onUnitClick(main, "floor")}
            style={{ textDecorationLine: "underline", color: "blue" }}
          >
            {data.value}
          </div>
        );
      }
      case "active": {
        return (
          <>
            {data.value ? (
              <Typography className={classes.activego}>Active</Typography>
            ) : (
              <Typography className={classes.inactivego}>Active</Typography>
            )}
          </>
        );
      }
      case "info": {
        return (
          <div style={{ display: props?.view ? "flex" : "none" }}>
            {props?.view && (
              <IconButton onClick={() => props.handleIcon("info", main)}>
                <InfoOutlinedIcon className={classes.infoIcon} />
              </IconButton>
            )}
          </div>
        );
      }
      case "payment_status": {
        return (
          <>
            {data.value === "Close" ? (
              <Typography className={classes.inactivego}>Close</Typography>
            ) : (
              <Typography className={classes.inprogress}>
                In Progress
              </Typography>
            )}
          </>
        );
      }
      case "q_status": {
        return (
          <>
            {
              <Typography
                className={
                  (data.value === "Won" && classes.green) ||
                  (data.value === "Active" && classes.yellow) ||
                  (data.value === "InActive" && classes.red)
                }
              >
                {data.value}
              </Typography>
            }
          </>
        );
      }

      // INSPECTION START

      case "inspection_status": {
        return (
          <Box>
            {props?.edit && data.value === "Yet To Assign" ? (
              <Box flexGrow={1} display="flex" alignItems="center">
                <Box>
                  <Typography className={classes.yet}>{data.value}</Typography>
                </Box>
                <Box marginLeft="30px">
                  <Button
                    sx={{ boxShadow: "none" }}
                    onClick={() => props.handleIcon("assign", data)}
                    variant="contained"
                  >
                    Assign
                  </Button>
                </Box>
              </Box>
            ) : (
              <Typography
                className={
                  data.value === "Assigned"
                    ? classes.assignstatus
                    : classes.completeStatus
                }
              >
                {data.value}
              </Typography>
            )}
          </Box>
        );
      }
      case "more_6": {
        return (
          <div style={{ display: props?.view ? "flex" : "none" }}>
            {props?.view && (
              <IconButton onClick={() => props.handleIcon("info", main)}>
                <MoreVertIcon className={classes.more} />
              </IconButton>
            )}
          </div>
        );
      }
      case "more_6_ins_item": {
        return (
          <div style={{ float: "right" }}>
            <IconButton>
              <MoreVertIcon
                className={classes.more}
                handleIcon={props?.handleIcon}
              />
            </IconButton>
          </div>
        );
      }
      case "more_7": {
        return (
          <div
            style={{ display: props?.view || props?.edit ? "flex" : "none" }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>
            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {props?.view && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("view", main);
                    }}
                    className={classes.menuItem}
                  >
                    View
                  </MenuItem>
                )}
                {props?.edit && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("active", main, main.status);
                    }}
                    className={classes.menuItem}
                  >
                    {main.status === "Active" ? "In-active" : "Active"}
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "object_status_inspection": {
        if (data?.value.value === "Yet to be Assign") {
          return (
            <Typography
              sx={{
                color: "#FF9340",
                fontSize: "14px",
              }}
            >
              {data.value.label}
            </Typography>
          );
        } else if (data?.value.value === "Assigned") {
          return (
            <Typography
              sx={{
                color: "#78B1FE !important",
                fontSize: "14px",
              }}
            >
              {data.value.label}
            </Typography>
          );
        } else if (data?.value.value === "Completed") {
          return (
            <Typography
              sx={{
                color: "#5AC782 !important",
                fontSize: "14px",
              }}
            >
              {data.value.label}
            </Typography>
          );
        } else {
          return data.value;
        }
      }
      case "inputBox": {
        return (
          <div style={{ display: props?.edit ? "flex" : "none" }}>
            {props?.edit && (
              <Checkbox
                checked={val?.is_active}
                onChange={(e) =>
                  props.handleIcon("checkBox", data, e.target.checked)
                }
              />
            )}
          </div>
        );
      }
      case "unused_balance": {
        return (
          <Typography
            sx={{
              color: data.value > 0 ? "#5AC782" : "auto",
              fontSize: "14px",
            }}
          >
            {data.value > 0 ? data.value : 0}
          </Typography>
        );
      }
      case "more_appraisal": {
        return (
          <div
            style={{ display: props?.edit || props?.delete ? "flex" : "none" }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {props?.edit &&
                  new Date(main?.data?.effective_start_date) <= new Date() &&
                  main?.Status !== "Active" && (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClose();
                        props.handleIcon("Activate", main);
                      }}
                      className={classes.menuItem}
                    >
                      Activate
                    </MenuItem>
                  )}

                {props?.delete && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("Delete", main);
                    }}
                    className={classes.menuItem}
                  >
                    Delete
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "inventory": {
        return (
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
            className={classes.text}
          >
            {data.value ? "Yes" : "No"}
          </Typography>
        );
      }
      case "api_key": {
        return (
          <div className={classes.iconText}>
            <div className={classes.iconLock}>
              <LockKey />
            </div>
            <div className={classes.iconPassword}>
              <Typography>***************</Typography>
            </div>
          </div>
        );
      }
      case "action_copy": {
        return (
          <div className={classes.copyIcon}>
            <CopyIcon />
          </div>
        );
      }
      case "action_edit": {
        return (
          <div
            className={classes.editIcon}
            onClick={() => props?.handleIcon("edit", main)}
          >
            <EditIcon />
          </div>
        );
      }
      case "hashtags": {
        return data?.value ? (
          <div className={classes.autoText}>
            <Typography>{data?.value}</Typography>
          </div>
        ) : (
          <></>
        );
      }
      case "status_alert": {
        if (data?.value === "Active") {
          return (
            <Typography
              sx={{
                color: "#11AA00",
                fontSize: "14px",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data?.value === "Inactive") {
          return (
            <Typography
              sx={{
                color: "#FF3232",
                fontSize: "14px",
              }}
            >
              {data.value}
            </Typography>
          );
        } else {
          return data.value;
        }
      }
      case "isActive": {
        if (data?.value) {
          return (
            <Typography
              sx={{
                color: "#11AA00 !important",
                fontSize: "14px",
                textTransform: "capitalize",
              }}
            >
              {data.value.toString()}
            </Typography>
          );
        } else {
          return (
            <Typography
              sx={{
                color: "#FF3232 !important",
                fontSize: "14px",
                textTransform: "capitalize",
              }}
            >
              {data.value.toString()}
            </Typography>
          );
        }
      }
      default: {
        return data.value;
      }
    }
  };

  //   const returnPageNumber = () => {
  //     const checkIndexKeyInType = props?.rows?.some((_) => _?.index);

  //     if (props?.rows?.length && checkIndexKeyInType) {
  //       if (props?.rows?.length === 1) {
  //         return `${props?.rows?.[0]?.index} of ${totalCount}`;
  //       } else {
  //         let lastCountIndex = props?.rows?.length - 1;
  //         return `${props?.rows?.[0]?.index} - ${props?.rows?.[lastCountIndex]?.index} of ${totalCount}`;
  //       }
  //     }
  //     return "";
  //   };

  return (
    <div className={classes.root}>
      {props?.rows?.length === 0 ? (
        <div
          className={classes.noRecordDiv}
          style={{
            minHeight: props?.height && props?.height,
            maxHeight: props?.height && props?.height,
          }}
        >
          <p className={classes.typo}>No Data Found</p>
        </div>
      ) : (
        <>
          <TableContainer
            className={classes.tableContainer}
            sx={{
              //zIndex: -1,
              minHeight: props?.height && props?.height,
              maxHeight: props?.height && props?.height,
              mt: 2,
            }}
          >
            {/* <br /> */}
            {/* Table Component */}
            <Table
              className={classes.totalTable}
              stickyHeader
              aria-label="customized table"
            >
              {/* heading */}
              <TableHead className={classes.thead}>
                {props?.heading?.length > 0 && (
                  <TableRow>
                    {props?.heading?.map((data, index) => {
                      return (
                        <StyledTableCell
                          headingColor={props?.headingColor}
                          size="small"
                          key={index}
                        >
                          {data.title}
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                )}
              </TableHead>
              {/* rows */}
              {props?.tableType === "normal" && <Box height={"8px"} />}
              <TableBody className={classes.tableBodyText}>
                {props?.rows?.length > 0 && (
                  <>
                    {props?.rows?.map((row, index) => {
                      return (
                        <>
                          <StyledTableRow
                            selected={row?.selectedRow}
                            key={index + 1}
                            className={classes.tbody}
                            style={{
                              backgroundColor:
                                row?.status && props?.isColor === true
                                  ? "#F5F7FA"
                                  : "auto",
                            }}
                            onClick={() =>
                              props?.onClick && props?.onClick(row)
                            }
                          >
                            {props?.dataType?.map((val) => {
                              return (
                                <StyledTableCell
                                  stickyHeader={true}
                                  headingColor={props?.cellColor}
                                  component="th"
                                  scope="row"
                                  onClick={() => {
                                    if (
                                      val.type[0] === "more" ||
                                      val.type[0] === "more_2" ||
                                      val.type[0] === "unit" ||
                                      val.type[0] === "block" ||
                                      val.type[0] === "textLink" ||
                                      val.type[0] === "floor" ||
                                      val.type[0] === "more_3" ||
                                      val?.type[0] === "info" ||
                                      val?.type[0] === "checkBox" ||
                                      val.type[0] === "more_4" ||
                                      val?.type[0] === "inspection_status" ||
                                      val?.type[0] === "inputBox" ||
                                      val?.type[0] === "view_more" ||
                                      val?.type[0] === "more_appraisal" ||
                                      val?.type[0] === "more_5" ||
                                      val?.type[0] === "api_key" ||
                                      val?.type[0] === "action_copy" ||
                                      val?.type[0] === "action_edit"
                                    ) {
                                    } else {
                                      props?.view &&
                                        props.handleIcon &&
                                        props.handleIcon("view", row);
                                    }
                                  }}
                                >
                                  {val.type.map((type) => {
                                    return getComponentType(row, {
                                      index: index,
                                      type: type,
                                      main: row,
                                      value: row[val.name],
                                      image: row[val.imagekey],
                                      icon: row[val.icon],
                                      is_active: row[val.is_active],
                                      objectOption: val?.label ?? "label",
                                    });
                                  })}
                                </StyledTableCell>
                              );
                            })}
                          </StyledTableRow>

                          {props.tableType === "normal" && (
                            <Box height={"8px"} />
                          )}
                        </>
                      );
                    })}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination Section */}
          {props?.showpagination && (
            <>
              <div className={classes.pagination}>
                {/* <p className={classes.typo}>
              pages {1}/{Math.ceil(props.rows.length / pageSize)}
            </p> */}
                {/* <div className={classes.flexRow}>
                  {props?.rows?.length > 0 && (
                    <p className={classes.typo}>{returnPageNumber()}</p>
                  )}
                  <Pagination
                    className={classes.paginate}
                    shape="rounded"
                    count={Math.ceil(totalCount / props?.limit)}
                    page={props?.page}
                    // onChange={handleChange}
                    onChange={(e, value) => props?.handlePagination(value)}
                    color="primary"
                    showFirstButton
                    showLastButton
                  />
                </div> */}

                <Pagination
                  className={classes.paginateNum}
                  color="primary"
                  count={10}
                  showFirstButton
                  showLastButton
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

TableWithPagination.defaultProps = {
  handleIcon: () => {},
  view: true,
  edit: true,
  delete: true,
};
