import makeStyles from "@mui/styles/makeStyles";
export const PopupComponentStyles = makeStyles((theme) => ({
  addRuleLabel: {
    fontSize: "14px",
    color: "#848484",
    marginTop: "40px",
    "& span": {
      color: "red",
    },
  },
  btns: {
    marginLeft: "auto",
    // borderTop: `1px solid ${theme.palette.border.lightGrey}`,
    paddingTop: "24px",
    marginTop: "80px",

    [theme.breakpoints.down("sm")]: {
      marginTop: "12px",
    },
  },
  btnone: {
    color: theme.typography.color.red,
    border: `1px solid ${theme.typography.color.darkGrey}`,
    padding: "10px 14px",
    borderRadius: "8px",
    fontSize: "14px",
    cursor: "pointer",
  },
  btn2: {
    color: theme.typography.color.white,
    backgroundColor: theme.typography.color.blue,
    border: `1px solid ${theme.typography.color.darkGrey}`,
    padding: "10px 20px",
    borderRadius: "8px",
    fontSize: "14px",
    marginLeft: "10px",
    cursor: "pointer",
  },
}));
