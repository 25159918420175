import React from "react";
import withNavBars from "../../HOCs/withNavBars";
import { TotalReports } from "./totalReports";

class TotalReportsParent extends React.Component {
  render() {
    return <TotalReports />;
  }
}

export default withNavBars(TotalReportsParent);
