import React from "react";
import { ChartCardScreen } from "./chartCardScreen";

class ChartCardScreenParent extends React.Component {
  render() {
    return <ChartCardScreen />;
  }
}

export default ChartCardScreenParent;
