import makeStyles from "@mui/styles/makeStyles";

export const SettingsScreenStyles = makeStyles((theme) => ({
  marginTop_1: {
    marginTop: "71px",
  },
  headerBox: {
    paddingLeft:"20px"
  },
  settingsTable: {
    padding: "0px 24px 24px 24px",
  },
}));
