import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import FilterSvg from "../../assets/svgIcons/filterIcon";
import AddSvg from "../../assets/svgIcons/add";
import { SearchFilter } from "../searchTextfield";
import { useStyles } from "./style";

export const Subheader = (props) => {
  const [selectbtn, setSelectbtn] = React.useState("Weekly");
  const calendar = [
    {
      label: "Weekly",
      value: "weekly",
    },
    {
      label: "Monthly",
      value: "monthly",
    },
  ];

  const classes = useStyles();

  return (
    <Box className={classes.header}>
      <Stack className={classes.headerFlex} direction="row" alignItems="center" justifyContent="space-between">
        {<Typography className={classes.title}>{props?.title}</Typography>}
        <Stack direction="row" className={classes.calendarSm}>
          {props?.calendar && (
            <Stack
              direction="row"
              alignItems="center"
              className={classes.calbtnGrp}>
              {calendar?.map((e, i) => {
                return (
                  <Box className={classes.btngroup}>
                    <Typography
                      className={
                        selectbtn === e.label
                          ? classes.buttontextClick
                          : classes.buttonText
                      }
                      key={i}
                      onClick={() => setSelectbtn(e.label)}
                    >
                      {e?.label}
                    </Typography>
                  </Box>
                );
              })}
            </Stack>
          )}
          <Stack direction="row" alignItems="center">
            {props?.filterIcon && (
              <Box className={classes.filterIconBox} mr={2}>
                <FilterSvg />
              </Box>
            )}
            {props?.searchFilter && (
              <Stack direction="row" alignItems="center">
                <SearchFilter placeholder="Search by Name" />
              </Stack>
            )}
            {props?.addIcon && (
              <Box
                className={classes.addIconBox}
                ml={2}
                onClick={props?.handleClick}
              >
                <AddSvg />
              </Box>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
