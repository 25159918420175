import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppRoutes } from "./routes";

import {
  NotFound,
  ChartCardScreen,
  TotalVendors,
  ApiDocumentation,
  AlertConfig,
} from "./../screens";
import TotalReports from "../screens/totalReports";
import SettingsScreen from "../screens/settingsScreen";
import TotalUsers from "../screens/totalUsers";
import { LoginScreen } from "../screens/loginScreen/loginScreen";
import PrivateRoute from "./privateRouter";
import AddRule from "../screens/addRule";

const RouterApp = (props) => {
  return (
    <BrowserRouter>
      <Routes>

        {/* Login Screen Route */}
        <Route path={AppRoutes.loginScreen} element={<LoginScreen />} />

        {/* Login Route */}
        {/* <Route path={AppRoutes.login} element={<Login />} /> */}

        {/* totalVendors Route */}
        <Route path={AppRoutes.totalVendors} element={
          <PrivateRoute path={AppRoutes.totalVendors}>
            <TotalVendors />
          </PrivateRoute>
        } />


        {/* ApiDocumentation Route */}
        <Route
          path={AppRoutes.apiDocumentation}
          element={
            <PrivateRoute path={AppRoutes.apiDocumentation}>
              <ApiDocumentation />
            </PrivateRoute>
          }
        />


        {/*AlertConfig  Route */}
        <Route path={AppRoutes.alertConfig} element={
          <PrivateRoute path={AppRoutes.alertConfig}>
            <AlertConfig />
          </PrivateRoute>
        } />

        {/* Total Reports Route */}
        <Route path={AppRoutes.totalReports} element={
          <PrivateRoute path={AppRoutes.totalReports}>
            <TotalReports />
          </PrivateRoute>
        } />

        {/* Settings Screen Route */}
        <Route path={AppRoutes.settingsScreen} element={
          <PrivateRoute path={AppRoutes.settingsScreen}>
            <SettingsScreen />
          </PrivateRoute>
        } />

        {/* Total Users Route */}
        <Route path={AppRoutes.totalUsers} element={
          <PrivateRoute path={AppRoutes.totalUsers}>
            <TotalUsers />
          </PrivateRoute>
        } />

        {/* Add Rule Route */}
        <Route path={AppRoutes.addRule} element={
          <PrivateRoute path={AppRoutes.addRule}>
            <AddRule />
          </PrivateRoute>
        } />

        {/* For unknow/non-defined path */}
        <Route path="*" element={<NotFound />} />

      </Routes>
    </BrowserRouter>
  );
};

export default RouterApp;
