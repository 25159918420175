import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Subheader } from "../../components/subHeader/SubHeader";
import { TableWithPagination } from "../../components/tableWithPagination";
import { SettingsScreenStyles } from "./style";

export const SettingsScreen = () => {
  const classes = SettingsScreenStyles();

  // Table - 2

  const Tablepath_2 = ["projectName", "apiKeyId", "apiKey", "action"];

  const Tableheading_2 = [
    { title: "Project Name", field: "projectName" },
    { title: "API Key ID", field: "apiKeyId" },
    { title: "API Key", field: "apiKey" },
    { title: "Action", field: "action" },
  ];

  function createData_2(projectName, apiKeyId, apiKey, action) {
    return {
      projectName,
      apiKeyId,
      apiKey,
      action,
    };
  }

  const ListTable_2 = [
    createData_2("Health Circles", "567yugf49plp2wew5gdu"),
    createData_2("Mind Body Food", "567yugf49plp2wew5gdu"),
    createData_2("Primary Care", "567yugf49plp2wew5gdu"),
    createData_2("Tanya Care", "567yugf49plp2wew5gdu"),
    createData_2("Property Automate", "567yugf49plp2wew5gdu"),
    createData_2("IDM", "567yugf49plp2wew5gdu"),
    createData_2("Colabo", "567yugf49plp2wew5gdu"),
    createData_2("Automatly", "567yugf49plp2wew5gdu"),
  ];

  return (
    <div>
      <Grid container className={classes.marginTop_1}>
        <Grid item xs={12}>
          <Box className={classes.headerBox}>
            <Subheader title="Settings" filterIcon searchFilter />
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.settingsTable}>
          <TableWithPagination
            heading={Tableheading_2}
            rows={ListTable_2}
            path={Tablepath_2}
            showpagination
            dataType={[
              { type: ["text"], name: "projectName" },
              { type: ["text"], name: "apiKeyId" },
              { type: ["api_key"], name: "apiKey" },
              { type: ["action_copy"], name: "action" },
            ]}
          />
        </Grid>
      </Grid>
    </div>
  );
};
