import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    padding: "6% 0 1% 0",
  },
  tooltip: {
    backgroundColor: "#464775",
    border: "none !Important",
    borderRadius: "none",
    fontSize: "10px",
    color: "#FFFFFF",
    width: "27px",
    height: "27px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "4px 7px",
    zIndex: 999,
    position: "relative",
  },
  graphDiv: {
    borderRadius: "8px",
    background: "#ffff",
    height: "400px !important",
    width: "auto !important",
  },
  chart: {
    height: "100%",
    width: "100% !important",
    "& svg": {
      width: "100% !important",
      height: "100% !important",
      "& g": {
        fontSize: "11px",
        "& line": {
          display: "none !important",
        },
      },
    },
  },
}));
