import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import React from "react";
import { LoginScreenStyles } from "./style";
import Logo1 from "../../../src/assets/alertHubLogo/favico.png";
import { TextBox } from "../../components/textbox";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { NetworkCall } from "../../networkCall";
import { config } from "../../config";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import { AppRoutes } from "../../router/routes";
import { AlertContext } from "../../contexts";

const initialState = {
  userName: "",
  password: "",
  showPassword: false,
  error: {
    userName: "",
    password: "",
  },
};

export const LoginScreen = () => {
  const classes = LoginScreenStyles();
  const [data, setData] = React.useState({ ...initialState });
  const alert = React.useContext(AlertContext);

  const navigate = useNavigate();

  const updateState = (key, value) => {
    let error = data.error;
    error[key] = "";
    setData({ ...data, [key]: value, error });
  };

  const validate = () => {
    let isValid = true;
    let error = data.error;
    if (data.userName.length === 0) {
      isValid = false;
      error.userName = "User Name is required";
    }
    if (data.password.length === 0) {
      isValid = false;
      error.password = "Password is required";
    }
    setData({ ...data, error });
    return isValid;
  };

  const handleClickShowPassword = () => {
    setData({ ...data, showPassword: !data?.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLogin = () => {
    if (validate()) {
      const payload = {
        username: data?.userName,
        password: data?.password,
      };
      NetworkCall(
        `${config.api_url}/users/login`,
        NetWorkCallMethods.post,
        payload,
        null,
        false,
        false
      )
        .then((res) => {
          localStorage.setItem(LocalStorageKeys.authToken, res?.data?.accessToken)
          localStorage.setItem(
            LocalStorageKeys.profileId,
            res?.data?.profileId
          );
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Logged in Successfully",
          });
          navigate(AppRoutes.totalVendors);
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Invalid Credientials",
          });
        });
    }
  };



  return (
    <div className={classes.main}>
      <Card sx={{ minWidth: "450px" }} className={classes.cardBox}>
        <CardContent>
          <div className={classes.contentText}>
            <div className={classes.logo}>
              <img src={Logo1} alt="logo" />
            </div>
            <div className={classes.signText}>
              <Typography>Sign In</Typography>
            </div>
            <div className={classes.bottomText}>
              <Typography>Sign In with your email and password</Typography>
            </div>
          </div>
          <div className={classes.formContent}>
            <div className={classes.email}>
              <TextBox
                onChange={(e) => updateState("userName", e.target.value)}
                isrequired
                label="Email Address"
                placeholder=""
                error={data?.error?.userName?.length > 0}
                helperText={
                  data?.error?.userName ? data?.error?.userName : false
                }
              />
            </div>
            <div className={classes.password}>
              <div className={classes.passwordField}>
                <div className={classes.passwordText}>
                  <Typography>
                    Password<span>*</span>
                  </Typography>
                </div>
                <div className={classes.forgotPassword}>
                  <Typography>Forgot Password?</Typography>
                </div>
              </div>
              <div className={classes.passwordBox}>
                <TextBox
                  type={data?.showPassword ? "text" : "password"}
                  onChange={(e) => updateState("password", e.target.value)}
                  label={""}
                  placeholder=""
                  error={data?.error?.password?.length > 0}
                  helperText={
                    data?.error?.password ? data?.error?.password : false
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {data.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
            </div>
          </div>
        </CardContent>
        <CardActions className={classes.cardAction}>
          <Button
            className={classes.signBtn}
            onClick={handleLogin}
            fullWidth
            size="small"
          >
            Sign In
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};
